<template>
  <div style="display: flex; flex-direction: column; align-items: center;margin-top: 150px;">
    <div style="width: 1244px;height: 461px;">
      <div style="display: flex;">
        <div style="width: 523px;height: 461px;display: flex; flex-direction: column;margin-left: 79px;margin-top: 129px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 50px;color: #1A202C;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">关于我们</a>
          <div style="width: 415px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #737578;
          line-height: 25px;text-align: left;font-style: normal;text-transform: none;margin-top: 32px;">
            TokenPocket是一款去中心化多链钱包，为用户提供手机钱包、插件钱包和硬件钱包，支持比特币、以太坊、币安智能链、
            TRON、Aptos、Polygon、Solana、Polkadot、EOS等公链以及所有EVM兼容链。TokenPocket服务于来自200多个国家和地区的2000多万用户。它是全球用户信赖的领先的加密钱包。
          </div>
        </div>
        <van-image style="width: 523px;height: 461px;margin-left: 50px;"
                   src="img/pc/about/pcAboutDt1.svg"></van-image>
      </div>
    </div>
    <div style="width: 100%;height: 430px;background: #3D8CFE;margin-top: 58px;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 33px;color: #FFFFFF;
      line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 74px;">TokenPocket深受全球用户信赖</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #BBD5FF;
      line-height: 20px;text-align: center;font-style: normal;text-transform: none;margin-top: 16px;">我们为全球200多个国家及地区的用户提供安全易用的数字资产服务</a>
      <div style="display: flex; align-items: center;margin-top: 49px;">
        <div style="width: 341px;height: 170px;border-radius: 13px 13px 13px 13px;border: 1px solid #7BAFFF;
        display: flex; flex-direction: column; align-items: center;">
          <van-image style="margin-top: 24px;"
                     src="img/h5/home/homext1.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">服务的用户</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 30px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 11px;">20M+</a>
        </div>
        <div style="width: 341px;height: 170px;border-radius: 13px 13px 13px 13px;border: 1px solid #7BAFFF;
        display: flex; flex-direction: column; align-items: center;margin-left: 28px;">
          <van-image style="margin-top: 24px;"
                     src="img/h5/home/homext2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">日均交易数</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 30px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 11px;">30M+</a>
        </div>
        <div style="width: 341px;height: 170px;border-radius: 13px 13px 13px 13px;border: 1px solid #7BAFFF;
        display: flex; flex-direction: column; align-items: center;margin-left: 28px;">
          <van-image style="margin-top: 24px;"
                     src="img/h5/home/homext3.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">国家及地区</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 30px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 11px;">200+</a>
        </div>
      </div>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #3A3B3D;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 49px;">我们的理念</a>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #737578;
    line-height: 24px;text-align: center;font-style: normal;text-transform: none;margin-top: 13px;">我们坚持开发技术社区，欢迎所有的开发者共同构建更便捷、更安全、更丰富的区块链世界</a>
    <div style="display: flex; align-items: center;margin-top: 84px;width: 1244px;">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <van-image src="img/pc/about/pcAboutXt1.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #3A3B3D;
        line-height: 30px;text-align: center;font-style: normal;text-transform: none;">目标</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: center;font-style: normal;text-transform: none;">让区块链随处发生</a>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center;margin-left: 159px;margin-right: 159px;">
        <van-image src="img/pc/about/pcAboutXt2.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #3A3B3D;
        line-height: 30px;text-align: center;font-style: normal;text-transform: none;">价值</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: center;font-style: normal;text-transform: none;">让数据回归用户，让价值归属用户</a>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center;">
        <van-image src="img/pc/about/pcAboutXt3.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #3A3B3D;
        line-height: 30px;text-align: center;font-style: normal;text-transform: none;">态度</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: center;font-style: normal;text-transform: none;">开放思维，互相协作</a>
      </div>
    </div>
    <div style="width: 100%;display: flex; flex-direction: column; align-items: center;background: #f6f9fb;margin-top: 128px;">
      <van-image src="img/pc/about/pcAboutDt2.svg"></van-image>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #3A3B3D;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 58px;">联系我们</a>
    <div style="display: flex; align-items: center;margin-top: 57px;width: 970px;">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <van-image src="img/pc/about/pcAboutXt4.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #000000;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">服务邮箱</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #737578;
        line-height: 24px;text-align: center;font-style: normal;text-transform: none;">service@tokenpocket.pro</a>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center;margin-left: 270px;margin-right: 270px;">
        <van-image src="img/pc/about/pcAboutXt5.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #000000;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">商务合作</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #737578;
        line-height: 24px;text-align: center;font-style: normal;text-transform: none;">bd@tokenpocket.pro</a>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center;">
        <van-image src="img/pc/about/pcAboutXt6.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #000000;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">开发者</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #737578;
        line-height: 24px;text-align: center;font-style: normal;text-transform: none;">Discord</a>
      </div>
    </div>
    <div style="width: 100%;height: 166px;background: #3D8CFE;margin-top: 117px;display: flex; flex-direction: column; align-items: center;">
      <div style="width: 1056px;">
        <div style="display: flex;">
          <div style="display: flex; flex-direction: column;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #FFFFFF;
            line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 28px;">现在就拥有您的TokenPocket!</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
            line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 5px;">安全可信赖的数字资产钱包，伴您探索区块链世界</a>
            <van-button class="buttonPc"
                        id="installButtonPc"
                        type="primary"
                        color="#FFFFFF"
                        style="width: 146px;height: 40px;margin-top: 15px;"
                        @click="goH5Downloads"
                        round>
              <a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;color: #3D8CFE;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">下载</a>
            </van-button>
          </div>
          <van-image style="width: 262.5px;height: 162.8px;margin-left: 458px;margin-top: 4px;"
                     src="img/pc/home/pcHomeDt6.svg"></van-image>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "aboutPcIndex",
}
</script>

<style>
</style>