<template>
  <div style="width: 100%;margin-top: 120px;display: flex; flex-direction: column; align-items: center;">
    <van-image style="width: 278px;height: 250px;"
               src="img/h5/recruiting/recruitingdt1.c1e8703.png"></van-image>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #1A202C;
    line-height: 28px;text-align: left;font-style: normal;text-transform: none;margin-top: 38px;">TP侠招募计划</a>
    <div style="margin-top: 25px;display: flex; flex-direction: column; align-items: center;width: 100%;font-family: PingFang HK, PingFang HK;font-weight: 500;
    font-size: 14px;color: #7A7A7A;line-height: 29px;text-align: center;font-style: normal;text-transform: none;">
      <a>加入TokenPocket社区</a>
      <a>投身到区块链世界，贡献自己的一份力量</a>
      <a>共同建设Web3.0世界</a>
    </div>
    <van-button type="primary"
                style="width: 346px;height: 48px;margin-top: 32px;"
                color="#2980FE;"
                round
                url="https://docs.google.com/forms/d/e/1FAIpQLSd3xlzB-ItayKeZJzZDt-qZVhYnc4OuhesWrtFeR6LD3CPjDg/viewform"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;
                color: #FFFFFF;line-height: 16px;text-align: left;font-style: normal;text-transform: none;">加入我们</a></van-button>
    <van-image style="margin-top: 107px;"
               src="img/h5/recruiting/recruitingdt3.png"></van-image>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #1A202C;
    line-height: 28px;text-align: left;font-style: normal;text-transform: none;margin-top: 11px;">关于TP侠</a>
    <div style="margin-top: 10px;display: flex; flex-direction: column; align-items: center;width: 100%;font-family: PingFang HK, PingFang HK;font-weight: 500;
    font-size: 14px;color: #7A7A7A;line-height: 29px;text-align: center;font-style: normal;text-transform: none;">
      <a>TP侠是TP社区中的重要一员，我们诚挚地邀请您成</a>
      <a>为全球TP侠中的一员！</a>
    </div>
    <div style="width: 346px;height: 35px;border-radius: 11px 11px 11px 11px;border: 1px solid #DDDDDD;margin-top: 10px;display: flex; align-items: center;">
      <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
       color: white; font-size: 12px;margin-left: 10px;">
        1
      </div>
      <a style="font-family: Inter, Inter;font-weight: 400;font-size: 11px;color: #555555;line-height: 13px;
      text-align: left;font-style: normal;text-transform: none;margin-left: 8px;">热衷于区块链事业并认可它的价值</a>
    </div>
    <div style="width: 346px;height: 35px;border-radius: 11px 11px 11px 11px;border: 1px solid #DDDDDD;margin-top: 10px;display: flex; align-items: center;">
      <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
       color: white; font-size: 12px;margin-left: 10px;">
        2
      </div>
      <a style="font-family: Inter, Inter;font-weight: 400;font-size: 11px;color: #555555;line-height: 13px;
      text-align: left;font-style: normal;text-transform: none;margin-left: 8px;">作为TokenPocket的忠诚用户，希望为探索区块链增加便利</a>
    </div>
    <van-image style="margin-top: 62px;"
               src="img/h5/recruiting/recruitingdt2.png"></van-image>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #1A202C;
    line-height: 28px;text-align: left;font-style: normal;text-transform: none;margin-top: 11px;">TP侠使命</a>
    <div style="margin-top: 10px;display: flex; flex-direction: column; align-items: center;width: 100%;font-family: PingFang HK, PingFang HK;font-weight: 500;
    font-size: 14px;color: #7A7A7A;line-height: 29px;text-align: center;font-style: normal;text-transform: none;">
      <a>在全球范围内，帮助TP钱包服务更多的区块链用户，我</a>
      <a>们希望您（满足以下其中两项要求即可报名）</a>
    </div>
    <div style="width: 346px;height: 48px;border-radius: 11px 11px 11px 11px;border: 1px solid #DDDDDD;margin-top: 10px;display: flex; align-items: center;">
      <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
       color: white; font-size: 12px;margin-left: 10px;">
        &#x2713;
      </div>
      <a style="font-family: Inter, Inter;font-weight: 400;font-size: 11px;color: #555555;line-height: 13px;
      text-align: left;font-style: normal;text-transform: none;margin-left: 8px;width: 293px;">能够通过多种渠道拓展并促进TP与所在国家的的营销公司或热门项目合作</a>
    </div>
    <div style="width: 346px;height: 35px;border-radius: 11px 11px 11px 11px;border: 1px solid #DDDDDD;margin-top: 17px;display: flex; align-items: center;">
      <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
       color: white; font-size: 12px;margin-left: 10px;">
        &#x2713;
      </div>
      <a style="font-family: Inter, Inter;font-weight: 400;font-size: 11px;color: #555555;line-height: 13px;
      text-align: left;font-style: normal;text-transform: none;margin-left: 8px;width: 293px;">能够策划符合当地用户需求的市场营销活动</a>
    </div>
    <div style="width: 346px;height: 48px;border-radius: 11px 11px 11px 11px;border: 1px solid #DDDDDD;margin-top: 17px;display: flex; align-items: center;">
      <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
       color: white; font-size: 12px;margin-left: 10px;">
        &#x2713;
      </div>
      <a style="font-family: Inter, Inter;font-weight: 400;font-size: 11px;color: #555555;line-height: 13px;
      text-align: left;font-style: normal;text-transform: none;margin-left: 8px;width: 293px;">具有其中一至几项主流社交媒体如Twitter、Youtube、Telegram、Discord等媒体的运营能力</a>
    </div>
    <div style="width: 346px;height: 35px;border-radius: 11px 11px 11px 11px;border: 1px solid #DDDDDD;margin-top: 17px;display: flex; align-items: center;">
      <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
       color: white; font-size: 12px;margin-left: 10px;">
        &#x2713;
      </div>
      <a style="font-family: Inter, Inter;font-weight: 400;font-size: 11px;color: #555555;line-height: 13px;
      text-align: left;font-style: normal;text-transform: none;margin-left: 8px;width: 293px;">具有流畅的英语水平，能完成对应的翻译工作</a>
    </div>
    <div style="width: 346px;height: 63px;border-radius: 11px 11px 11px 11px;border: 1px solid #DDDDDD;margin-top: 17px;display: flex; align-items: center;">
      <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
       color: white; font-size: 12px;margin-left: 10px;">
        &#x2713;
      </div>
      <a style="font-family: Inter, Inter;font-weight: 400;font-size: 11px;color: #555555;line-height: 13px;
      text-align: left;font-style: normal;text-transform: none;margin-left: 8px;width: 293px;">TokenPocket当前计划为区块链市场提供更多的福利，如果您来自印度、美国、土耳其、俄罗斯、韩国、越南、菲律宾等，我们将给您提供更多的工作支持</a>
    </div>
    <van-button type="primary"
                style="width: 346px;height: 48px;margin-top: 25px;"
                color="#2980FE;"
                round
                url="https://docs.google.com/forms/d/e/1FAIpQLSd3xlzB-ItayKeZJzZDt-qZVhYnc4OuhesWrtFeR6LD3CPjDg/viewform"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;
                color: #FFFFFF;line-height: 16px;text-align: left;font-style: normal;text-transform: none;">加入我们</a></van-button>
    <div style="height: 637px;background: #07459F;width: 100%;margin-top: 53px;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #FFFFFF;
      line-height: 28px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">我们提供的福利</a>
      <div style="margin-top: 21px;display: flex; align-items: center;">
        <van-image src="img/h5/recruiting/recruitingdt4.svg"></van-image>
        <van-image style="margin-left: 11px;"
                   src="img/h5/recruiting/recruitingdt5.svg"></van-image>
      </div>
      <div style="margin-top: 16px;display: flex; align-items: center;">
        <van-image src="img/h5/recruiting/recruitingdt6.svg"></van-image>
        <van-image style="margin-left: 11px;"
                   src="img/h5/recruiting/recruitingdt7.svg"></van-image>
      </div>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #1A202C;
      line-height: 28px;text-align: left;font-style: normal;text-transform: none;margin-top: 30px;">招募流程</a>
    <div style="margin-top: 39px;display: flex;">
      <div>
        <van-image src="img/h5/recruiting/recruitingxt4.png"></van-image>
        <van-image style="margin-top: 26px;margin-bottom: 50px;margin-left: 11px;margin-right: 15px;"
                   src="img/h5/recruiting/recruitingxt5.png"></van-image>
      </div>
      <div>
        <van-image src="img/h5/recruiting/recruitingxt6.png"></van-image>
        <van-image style="margin-top: 26px;margin-bottom: 50px;margin-left: 11px;margin-right: 15px;"
                   src="img/h5/recruiting/recruitingxt5.png"></van-image>
      </div>
      <div>
        <van-image src="img/h5/recruiting/recruitingxt7.png"></van-image>
      </div>
    </div>
    <div style="margin-top: 17px;display: flex;">
      <div>
        <van-image src="img/h5/recruiting/recruitingxt8.png"></van-image>
        <van-image style="margin-top: 26px;margin-bottom: 50px;margin-left: 11px;margin-right: 15px;"
                   src="img/h5/recruiting/recruitingxt5.png"></van-image>
      </div>
      <div>
        <van-image src="img/h5/recruiting/recruitingxt9.png"></van-image>
      </div>
    </div>
    <van-button type="primary"
                style="width: 346px;height: 48px;margin-top: 30px;"
                url="https://docs.google.com/forms/d/e/1FAIpQLSd3xlzB-ItayKeZJzZDt-qZVhYnc4OuhesWrtFeR6LD3CPjDg/viewform"
                round
                plain
                hairline>
      <a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;color: #3A88FE;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;">加入我们</a>
    </van-button>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #1A202C;
      line-height: 28px;text-align: left;font-style: normal;text-transform: none;margin-top: 84px;">招募对象</a>
    <div style="display: flex; align-items: center;margin-top: 21px;">
      <div style="width: 106px;height: 153px;border-radius: 15px 15px 15px 15px;border: 1px solid #A4C7FF;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #3988FD;
        line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-top: 6px;margin-left: 10px;">1</a>
        <div style="margin-top: 8px;display: flex; flex-direction: column; align-items: center;">
          <van-image src="img/h5/recruiting/recruitingxt1.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #000000;
          line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">来自全球任何地方</a>
        </div>
      </div>
      <div style="width: 106px;height: 153px;border-radius: 15px 15px 15px 15px;border: 1px solid #A4C7FF;margin-left: 14px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #3988FD;
        line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-top: 6px;margin-left: 10px;">2</a>
        <div style="margin-top: 8px;display: flex; flex-direction: column; align-items: center;">
          <van-image src="img/h5/recruiting/recruitingxt2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #000000;
          line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">对区块链保持着无尽</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #000000;
          line-height: 12px;text-align: left;font-style: normal;text-transform: none;">的好奇和热情</a>
        </div>
      </div>
      <div style="width: 106px;height: 153px;border-radius: 15px 15px 15px 15px;border: 1px solid #A4C7FF;margin-left: 14px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #3988FD;
        line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-top: 6px;margin-left: 10px;">3</a>
        <div style="margin-top: 8px;display: flex; flex-direction: column; align-items: center;">
          <van-image src="img/h5/recruiting/recruitingxt3.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #000000;
          line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">填写表格并附上简</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #000000;
          line-height: 12px;text-align: left;font-style: normal;text-transform: none;">历，我们会尽快联系</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #000000;
          line-height: 12px;text-align: left;font-style: normal;text-transform: none;">您</a>
        </div>
      </div>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #1A202C;
      line-height: 28px;text-align: left;font-style: normal;text-transform: none;margin-top: 66px;">关于TokenPocket</a>
    <van-image style="margin-top: 15px;"
               src="img/h5/recruiting/recruitingdt8.svg"></van-image>
    <van-image style="margin-top: 9px;"
               src="img/h5/recruiting/recruitingdt9.svg"></van-image>
    <div style="width: 346px;height: 141px;border-radius: 16px 16px 16px 16px;border: 1px solid #E3E3E3;
    display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 23px;">
      <a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;color: #000000;
      line-height: 16px;text-align: left;font-style: normal;text-transform: none;">TokenPocket 全球领先的多链自托管钱包</a>
      <van-image style="margin-top: 15px;"
                 src="img/h5/recruiting/recruitingdt10.svg"></van-image>
    </div>
    <van-button type="primary"
                style="width: 346px;height: 48px;margin-top: 20px;margin-bottom: 21px;"
                color="#2980FE;"
                round
                url="https://docs.google.com/forms/d/e/1FAIpQLSd3xlzB-ItayKeZJzZDt-qZVhYnc4OuhesWrtFeR6LD3CPjDg/viewform"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;
                color: #FFFFFF;line-height: 16px;text-align: left;font-style: normal;text-transform: none;">加入我们</a></van-button>
  </div>
</template>
<script>
export default {
  name: "recruitingIndex",
}
</script>

<style>
</style>