<template>
  <div style="width: 100%;margin-top: 80px;display: flex; flex-direction: column; align-items: center;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #1A202C;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;">关于我们</a>
    <div style="width: 100%;margin-top: 13px;display: flex; flex-direction: column; align-items: center;font-family: PingFang HK, PingFang HK;
    font-weight: 500;font-size: 15px;color: #7A7A7A;line-height: 22px;text-align: center;font-style: normal;text-transform: none;">
      <a>TokenPocket是一款去中心化多链钱包，为用户</a>
      <a>提供手机钱包、插件钱包和硬件钱包，支持比特</a>
      <a>币、以太坊、币安智能链、TRON、Aptos、</a>
      <a>Polygon、Solana、Polkadot、EOS等公链以及</a>
      <a>所有EVM兼容链。TokenPocket服务于来自200</a>
      <a>多个国家和地区的2000多万用户。它是全球用</a>
      <a>户信赖的领先的加密钱包。</a>
    </div>
    <van-image style="margin-top: 43px;"
               src="img/h5/about/aboutdt1.png"></van-image>
    <div style="margin-top: 28px;width: 100%;height: 634px;background: #3D8CFE;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #FFFFFF;
        line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 46px;">TokenPocket深受全球用户信赖</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #FFFFFF;
        line-height: 20px;text-align: center;font-style: normal;text-transform: none;margin-top: 8px;">我们为全球200多个国家及地区的用户提供安全易用的</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #FFFFFF;
        line-height: 20px;text-align: center;font-style: normal;text-transform: none;">数字资产服务</a>
      <div style="width: 327px;height: 138px;border-radius: 13px 13px 13px 13px;border: 2px solid #D3E2FA;margin-top: 28px;
        display: flex; flex-direction: column; align-items: center;">
        <van-image style="margin-top: 20px;"
                   src="img/h5/home/homext1.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">服务的用户</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">20M+</a>
      </div>
      <div style="width: 327px;height: 138px;border-radius: 13px 13px 13px 13px;border: 2px solid #D3E2FA;margin-top: 12px;
        display: flex; flex-direction: column; align-items: center;">
        <van-image style="margin-top: 20px;"
                   src="img/h5/home/homext2.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">日均交易数</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">30M+</a>
      </div>
      <div style="width: 327px;height: 138px;border-radius: 13px 13px 13px 13px;border: 2px solid #D3E2FA;margin-top: 12px;
        display: flex; flex-direction: column; align-items: center;">
        <van-image style="margin-top: 20px;"
                   src="img/h5/home/homext3.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">国家及地区</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">200+</a>
      </div>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #1A202C;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 42px;">我们的理念</a>
    <div style="width: 100%;margin-top: 13px;display: flex; flex-direction: column; align-items: center;font-family: PingFang HK, PingFang HK;
    font-weight: 500;font-size: 15px;color: #7A7A7A;line-height: 22px;text-align: center;font-style: normal;text-transform: none;">
      <a>我们坚持开发技术社区，欢迎所有的开发者共同构建</a>
      <a>更便捷、更安全、更丰富的区块链世界</a>
    </div>
    <van-image style="margin-top: 53px;"
               src="img/h5/about/aboutxt1.png"></van-image>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #3A3B3D;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 22px;">目标</a>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
    line-height: 24px;text-align: center;font-style: normal;text-transform: none;">让区块链随处发生</a>
    <van-image style="margin-top: 72px;"
               src="img/h5/about/aboutxt2.png"></van-image>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #3A3B3D;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 28px;">价值</a>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
    line-height: 24px;text-align: center;font-style: normal;text-transform: none;">让数据回归用户，让价值归属用户</a>
    <van-image style="margin-top: 47px;"
               src="img/h5/about/aboutxt3.png"></van-image>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #3A3B3D;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 28px;">态度</a>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
    line-height: 24px;text-align: center;font-style: normal;text-transform: none;">开放思维，互相协作</a>
    <div style="width: 100%;height: 1201px;background: #F6F9FB;margin-top: 60px;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #3A3B3D;
      line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 39px;">里程碑</a>
      <van-image style="margin-top: 29px;"
                 src="img/h5/about/aboutdt2.svg"></van-image>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #3A3B3D;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 60px;">联系我们</a>
    <van-image style="margin-top: 69px;"
               src="img/h5/about/aboutxt4.png"></van-image>
    <a style="margin-top: 20px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #000000;
    line-height: 24px;text-align: left;font-style: normal;text-transform: none;">服务邮箱</a>
    <a style="margin-top: 4px;font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #737578;
    line-height: 24px;text-align: center;font-style: normal;text-transform: none;">service@tokenpocket.pro</a>
    <van-image style="margin-top: 62px;"
               src="img/h5/about/aboutxt5.png"></van-image>
    <a style="margin-top: 20px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #000000;
    line-height: 24px;text-align: left;font-style: normal;text-transform: none;">商务合作</a>
    <a style="margin-top: 4px;font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #737578;
    line-height: 24px;text-align: center;font-style: normal;text-transform: none;">bd@tokenpocket.pro</a>
    <van-image style="margin-top: 62px;"
               src="img/h5/about/aboutxt6.png"></van-image>
    <a style="margin-top: 20px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #000000;
    line-height: 24px;text-align: left;font-style: normal;text-transform: none;">开发者</a>
    <a style="margin-top: 4px;font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #737578;
    line-height: 24px;text-align: center;font-style: normal;text-transform: none;">Discord</a>
    <div style="width: 100%;height: 394px;background: #3D8CFE;margin-top: 61px;
      display: flex; flex-direction: column; align-items: center;">
      <div style="width: 332px;margin-top: 33px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #FFFFFF;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">现在就拥有您的TokenPocket!</a>
        <div style="margin-top: 5px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
            line-height: 24px;text-align: left;font-style: normal;text-transform: none;">安全可信赖的数字资产钱包，伴您探索区块链世界</a>
        </div>
        <van-button class="buttonPc"
                    id="installButtonPc"
                    type="primary"
                    color="#FFFFFF"
                    style="width: 133px;height: 40px;margin-top: 15px;"
                    @click="goH5Downloads"
                    round>
          <a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;color: #3D8CFE;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">立即下载</a>
        </van-button>
        <van-image style="margin-top: 41px;"
                   src="img/h5/home/homedt6.png"></van-image>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "aboutIndex",
  methods: {
    goH5Downloads () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/download' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
  }
}
</script>

<style>
</style>