<template>
  <div style="width: 100%;margin: auto;">
    <div style="margin-top: 120px;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #1A202C;
      line-height: 38px;text-align: center;font-style: normal;text-transform: none;">让区块链随处发生</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #7A7A7A;
      line-height: 22px;text-align: center;font-style: normal;text-transform: none;margin-top: 13px;">让你安全、简单地探索区块链，是全球领先</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #7A7A7A;
      line-height: 22px;text-align: center;font-style: normal;text-transform: none;">的数字资产钱包</a>
      <div style="margin-top: 32px;display: flex;">
        <van-button class="buttonPc"
                    id="installButtonPc"
                    type="primary"
                    color="#2A80EA"
                    style="width: 142px;height: 43px;"
                    @click="goH5Downloads"
                    round>
          <div style="display: flex;align-items: center;">
            <van-image style="width: 16px;height: 16px;"
                       src="img/h5/buttonImg1.png"></van-image>
            <a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;color: #FFFFFF;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">下载</a>
          </div>
        </van-button>
        <van-button type="primary"
                    style="width: 142px;height: 43px;margin-left: 20px;"
                    url="https://www.keypal.pro/?locale=zh#/"
                    round
                    plain
                    hairline>
          <div style="display: flex;align-items: center;">
            <van-image src="img/h5/buttonMsg2.png"></van-image>
            <a style="font-family: Inter, Inter;font-weight: 500;font-size: 14px;color: #3A3B3D;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">KeyPal</a>
          </div>
        </van-button>
      </div>
      <van-image style="margin-top: 30px;width: 375px;height: 447.5px;"
                 src="img/h5/home/header-img-zh.9667d1a.png"></van-image>
      <div style="margin-top: 28px;width: 100%;height: 634px;background: #3D8CFE;display: flex; flex-direction: column; align-items: center;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #FFFFFF;
        line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 46px;">TokenPocket深受全球用户信赖</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #FFFFFF;
        line-height: 20px;text-align: center;font-style: normal;text-transform: none;margin-top: 8px;">我们为全球200多个国家及地区的用户提供安全易用的</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #FFFFFF;
        line-height: 20px;text-align: center;font-style: normal;text-transform: none;">数字资产服务</a>
        <div style="width: 327px;height: 138px;border-radius: 13px 13px 13px 13px;border: 2px solid #D3E2FA;margin-top: 28px;
        display: flex; flex-direction: column; align-items: center;">
          <van-image style="margin-top: 20px;"
                     src="img/h5/home/homext1.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">服务的用户</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">20M+</a>
        </div>
        <div style="width: 327px;height: 138px;border-radius: 13px 13px 13px 13px;border: 2px solid #D3E2FA;margin-top: 12px;
        display: flex; flex-direction: column; align-items: center;">
          <van-image style="margin-top: 20px;"
                     src="img/h5/home/homext2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">日均交易数</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">30M+</a>
        </div>
        <div style="width: 327px;height: 138px;border-radius: 13px 13px 13px 13px;border: 2px solid #D3E2FA;margin-top: 12px;
        display: flex; flex-direction: column; align-items: center;">
          <van-image style="margin-top: 20px;"
                     src="img/h5/home/homext3.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">国家及地区</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">200+</a>
        </div>
      </div>
      <van-image style="margin-top: 40px;width: 375px;height: 369px;"
                 src="img/h5/home/security-img-zh.ce3c758.png"></van-image>
      <div style="width: 334px;display: flex; flex-direction: column;margin-top: 14px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #3A3B3D;
      line-height: 30px;text-align: left;font-style: normal;text-transform: none;">安全是我们的根基</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 8px;">TokenPocket只在用户的设备里生成并存储私钥助记词，您是唯一可以访问的人</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;">TokenPocket同时开发了冷钱包、多签钱包等，来满足您对安全的需求</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;">多链钱包，支持BTC、ETH、BSC、TRON、Aptos、Polygon、Solana、Cosmos、Polkadot、EOS、IOST等</a>
      </div>
      <div style="margin-top: 32px;display: flex;">
        <van-button class="buttonPc"
                    id="installButtonPc"
                    type="primary"
                    color="#2A80EA"
                    style="width: 142px;height: 43px;"
                    @click="goH5Downloads"
                    round>
          <div style="display: flex;align-items: center;">
            <van-image style="width: 16px;height: 16px;"
                       src="img/h5/buttonImg1.png"></van-image>
            <a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;color: #FFFFFF;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">下载</a>
          </div>
        </van-button>
        <van-button type="primary"
                    style="width: 142px;height: 43px;margin-left: 20px;"
                    url="https://www.keypal.pro/?locale=zh#/"
                    round
                    plain
                    hairline>
          <div style="display: flex;align-items: center;">
            <van-image src="img/h5/buttonMsg2.png"></van-image>
            <a style="font-family: Inter, Inter;font-weight: 500;font-size: 14px;color: #3A3B3D;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">KeyPal</a>
          </div>
        </van-button>
      </div>
      <van-image style="margin-top: 70px;width: 375px;height: 326px;"
                 src="img/h5/home/exchange-img.9e6d4f8.png"></van-image>
      <div style="width: 334px;display: flex; flex-direction: column;margin-top: 36px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #3A3B3D;
      line-height: 30px;text-align: left;font-style: normal;text-transform: none;">兑换、交易更简单</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 8px;">随时随地，让你更方便地交易</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;">用你的信用卡购入数字资产，轻松存储、发送、跨链与兑换</a>
        <div style="margin-top: 32px;width: 327px;height: 52px;border-radius: 8px 8px 8px 8px;border: 1px solid #E6E8EA;
        display: flex; align-items: center; justify-content: space-between;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-left: 17px;">闪兑</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-right: 17px;"
             href="https://swap.transit.finance/?locale=zh#/"
             target="_blank">简单快速 ></a>
        </div>
        <div style="margin-top: 9px;width: 327px;height: 52px;border-radius: 8px 8px 8px 8px;border: 1px solid #E6E8EA;
        display: flex; align-items: center; justify-content: space-between;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-left: 17px;">跨链</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-right: 17px;"
             href="https://swap.transit.finance/?locale=zh#/"
             target="_blank">多链转换 ></a>
        </div>
        <div style="margin-top: 9px;width: 327px;height: 52px;border-radius: 8px 8px 8px 8px;border: 1px solid #E6E8EA;
        display: flex; align-items: center; justify-content: space-between;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-left: 17px;">购入数字资产</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-right: 17px;"
             href="https://openc.pro/widget-page/?widgetId=UjcyR3hQVlk"
             target="_blank">仅在5分钟内 ></a>
        </div>
      </div>
      <van-image style="margin-top: 43px;width: 375px;height: 298px;"
                 src="img/h5/home/dapp-store-img.d71690c.png"></van-image>
      <div style="width: 334px;display: flex; flex-direction: column;margin-top: 28px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #3A3B3D;
      line-height: 30px;text-align: left;font-style: normal;text-transform: none;">一站式DApp商店</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 8px;">找到你最喜爱的去中心化应用，发现最新、最热的</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;">DApp，一切尽在TokenPocket</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;">集成DApp浏览器，您可以访问你的任何DApp链接</a>
      </div>
      <div style="margin-top: 32px;width: 327px;height: 67px;border-radius: 16px 16px 16px 16px;border: 1px solid #E6E8EA;
      display: flex;align-items: center;">
        <div style="display: flex; flex-direction: column; align-items: center;margin-left: 35px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;">DeFi</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
          line-height: 24px;text-align: right;font-style: normal;text-transform: none;">已支持</a>
        </div>
        <div style="width: 0px;height: 46px;border-radius: 1px 1px 1px 1px;border: 1px solid #E6E8EA;margin-left: 35px;"></div>
        <div style="display: flex; flex-direction: column; align-items: center;margin-left: 35px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;">NFT</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
          line-height: 24px;text-align: right;font-style: normal;text-transform: none;">已支持</a>
        </div>
        <div style="width: 0px;height: 46px;border-radius: 1px 1px 1px 1px;border: 1px solid #E6E8EA;margin-left: 35px;"></div>
        <div style="display: flex; flex-direction: column; align-items: center;margin-left: 35px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;">DApp</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
          line-height: 24px;text-align: right;font-style: normal;text-transform: none;">已支持</a>
        </div>
      </div>
      <van-image style="margin-top: 92px;width: 375px;height: 251px;"
                 src="img/h5/home/homedt5.png"></van-image>
      <div style="width: 334px;display: flex; flex-direction: column;margin-top: 38px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #3A3B3D;
      line-height: 30px;text-align: left;font-style: normal;text-transform: none;">社区</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #737578;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 8px;">我们坚持开放性社区，始终如一！我们欢迎所有开发者一起开发更方便、更丰富的的区块链世界</a>
      </div>
      <van-button type="primary"
                  style="width: 327px;height: 52px;margin-top: 40px;"
                  url="https://github.com/TP-Lab"
                  color="#E6E8EA"
                  plain
                  hairline>
        <div style="display: flex; align-items: center; justify-content: space-between;width: 327px;">
          <div style="margin-left: 27px;display: flex; align-items: center;">
            <van-image src="img/h5/home/buttonMsg3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
            line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-left: 19px;">TP-Lab</a>
          </div>
          <a style="margin-right: 27px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-right: 17px;"> > </a>
        </div>
      </van-button>
      <van-button type="primary"
                  style="width: 327px;height: 52px;margin-top: 9px;"
                  url="https://discord.com/invite/NKPM8TXFQk"
                  color="#E6E8EA"
                  plain
                  hairline>
        <div style="display: flex; align-items: center; justify-content: space-between;width: 327px;">
          <div style="margin-left: 27px;display: flex; align-items: center;">
            <van-image src="img/h5/home/buttonMsg4.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
            line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-left: 19px;">开发者社区</a>
          </div>
          <a style="margin-right: 27px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-right: 17px;"> > </a>
        </div>
      </van-button>
      <van-button type="primary"
                  style="width: 327px;height: 52px;margin-top: 9px;"
                  url="https://help.tokenpocket.pro/developer-cn/"
                  color="#E6E8EA"
                  plain
                  hairline>
        <div style="display: flex; align-items: center; justify-content: space-between;width: 327px;">
          <div style="margin-left: 27px;display: flex; align-items: center;">
            <van-image src="img/h5/home/buttonMsg5.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
            line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-left: 19px;">开发者文档</a>
          </div>
          <a style="margin-right: 27px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-right: 17px;"> > </a>
        </div>
      </van-button>
      <div style="width: 100%;height: 394px;background: #3D8CFE;margin-top: 61px;
      display: flex; flex-direction: column; align-items: center;">
        <div style="width: 332px;margin-top: 33px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #FFFFFF;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">现在就拥有您的TokenPocket!</a>
          <div style="margin-top: 5px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
            line-height: 24px;text-align: left;font-style: normal;text-transform: none;">安全可信赖的数字资产钱包，伴您探索区块链世界</a>
          </div>
          <van-button class="buttonPc"
                      id="installButtonPc"
                      type="primary"
                      color="#FFFFFF"
                      style="width: 133px;height: 40px;margin-top: 15px;"
                      @click="goH5Downloads"
                      round>
            <a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;color: #3D8CFE;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">立即下载</a>
          </van-button>
          <div style="display: flex; align-items: center;flex-direction: column;">
            <div style="width: 375px;height: 220px;">
              <van-image style="margin-top: 27px;width: 375px;height: 220px;"
                         src="img/h5/home/homedt6.png"></van-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeIndex",
  methods: {
    goH5Downloads () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/download' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
  }
}
</script>

<style>
</style>