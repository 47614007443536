<template>
  <div style="width: 100%;margin-top: 80px;display: flex; flex-direction: column; align-items: center;">
    <van-image src="img/h5/dapp/dappdt1.png"
               style="width: 375px;"></van-image>
    <div style="width: 350px;display: flex; flex-direction: column;">
      <div style="margin-top: 33px;">
        <a style="font-family: Roboto, Roboto;font-weight: bold;font-size: 17px;color: rgba(0,0,0,0.85);
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">项目信息： </a><a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">为必填项</a>
      </div>
      <div style="display: flex;align-items: center;">
        <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-top: 5px;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">项目网络</a>
      </div>
      <select style="width: 346px;height: 38px;border: 1px solid #D9D9D9;padding: 0 10px;">
        <option>以太坊</option>
        <option>币安智能链</option>
        <option>火币生态链</option>
        <option>OKExChain</option>
        <option>TRON</option>
        <option>EOS</option>
        <option>IOST</option>
        <option>Polkadot</option>
      </select>
      <div style="display: flex;align-items: center;margin-top: 27px;">
        <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-top: 5px;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">项目名称</a>
      </div>
      <div style="width: 346px;height: 38px;border-radius: 2px 2px 2px 2px;border: 1px solid #D9D9D9;">
        <van-cell-group inset
                        style="width: 330px;height: 38px;">
          <van-field v-model="value" />
        </van-cell-group>
      </div>
      <div style="display: flex;align-items: center;margin-top: 27px;">
        <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-top: 5px;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">项目链接</a>
      </div>
      <div style="width: 346px;height: 38px;border-radius: 2px 2px 2px 2px;border: 1px solid #D9D9D9;">
        <van-cell-group inset
                        style="width: 330px;height: 38px;">
          <van-field v-model="value2" />
        </van-cell-group>
      </div>
      <div style="display: flex;align-items: center;margin-top: 27px;">
        <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-top: 5px;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">项目简介</a>
      </div>
      <div style="width: 346px;height: 105px;border-radius: 2px 2px 2px 2px;border: 1px solid #D9D9D9;">
        <van-cell-group inset>
          <van-field v-model="value3"
                     rows="3"
                     autosize
                     type="textarea"
                     maxlength="100"
                     placeholder="一句话简单描述项目，将出现在DApp副标题里"
                     show-word-limit
                     style="width: 330px;height: 105px;" />
        </van-cell-group>
      </div>
    </div>
    <van-button type="primary"
                style="width: 346px;height: 36px;margin-top: 40px;"
                color="#2980FE;"
                url="https://github.com/TP-Lab/dapps">
      <div style="display: flex;align-items: center;">
        <van-image src="img/pc/dapp/pcDappXt1.png"></van-image>
        <a style="font-family: Roboto, Roboto;font-weight: 500;font-size: 13px;margin-left: 11px;
                  color: #FFFFFF;line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Github</a>
      </div>
    </van-button>
    <div style="width: 350px;display: flex; flex-direction: column;margin-top: 27px;">
      <div style="display: flex;align-items: center;">
        <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-top: 5px;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">是否支持TokenPocket连接（包括移动端和插件端）</a>
      </div>
      <select style="width: 346px;height: 38px;border: 1px solid #D9D9D9;padding: 0 10px;margin-top: 10px;">
        <option>均已支持</option>
        <option>仅支持插件端</option>
        <option>仅支持移动端</option>
        <option>均未支持</option>
      </select>
      <div style="display: flex;align-items: center;margin-top: 27px;">
        <div style="display: flex;align-items: center;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-top: 5px;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">是否有返佣机制</a>
        </div>
        <van-radio-group v-model="checked"
                         direction="horizontal"
                         shape="dot"
                         style="margin-left: 20px;">
          <van-radio name="1"
                     icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">没有</a></van-radio>
          <van-radio name="2"
                     icon-size="16px"
                     style="margin-left: 20px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">有</a></van-radio>
        </van-radio-group>
      </div>
      <div style="margin-top: 33px;">
        <a style="font-family: Roboto, Roboto;font-weight: bold;font-size: 17px;color: rgba(0,0,0,0.85);
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">审计信息： </a><a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">为必填项</a>
      </div>
      <div style="display: flex;align-items: center;">
        <div style="display: flex;align-items: center;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-top: 5px;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">是否已进行合约审计</a>
        </div>
        <van-radio-group v-model="checked2"
                         direction="horizontal"
                         shape="dot"
                         style="margin-left: 20px;">
          <van-radio name="1"
                     icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">没有</a></van-radio>
          <van-radio name="2"
                     icon-size="16px"
                     style="margin-left: 20px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">有</a></van-radio>
        </van-radio-group>
      </div>
      <div style="margin-top: 33px;">
        <a style="font-family: Roboto, Roboto;font-weight: bold;font-size: 17px;color: rgba(0,0,0,0.85);
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">联系方式：</a><a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">请务必留下除邮箱以外的客服联系方式，否则无法通过审核</a>
      </div>
      <div style="display: flex;align-items: center;margin-top: 27px;">
        <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-top: 5px;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">邮箱</a>
      </div>
      <div style="width: 346px;height: 38px;border-radius: 2px 2px 2px 2px;border: 1px solid #D9D9D9;">
        <van-cell-group inset
                        style="width: 330px;height: 38px;">
          <van-field v-model="value4"
                     placeholder="dapp@tokenpocket.pro" />
        </van-cell-group>
      </div>
      <div style="display: flex;align-items: center;margin-top: 27px;">
        <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-top: 5px;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #606266;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;margin-left: 4px;">其他</a>
      </div>
      <div style="width: 346px;height: 38px;border-radius: 2px 2px 2px 2px;border: 1px solid #D9D9D9;">
        <van-cell-group inset
                        style="width: 330px;height: 38px;">
          <van-field v-model="value5"
                     placeholder="如：Discord：1234" />
        </van-cell-group>
      </div>
    </div>
    <van-button type="primary"
                style="width: 172px;height: 40px;margin-top: 40px;margin-bottom: 40px;"
                color="#2980FE;"
                url="https://github.com/TP-Lab/tokens"><a style="font-family: Roboto, Roboto;font-weight: 500;font-size: 13px;
                  color: #FFFFFF;line-height: 22px;text-align: left;font-style: normal;text-transform: none;">提交</a></van-button>
    <div style="width: 350px;display: flex; flex-direction: column;">
      <a style="font-family: Roboto, Roboto;font-weight: bold;font-size: 17px;color: rgba(0,0,0,0.85);
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;">DApp要求</a>
      <div style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: rgba(0,0,0,0.85);
      line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-top: 28px;display: flex; flex-direction: column;">
        <a>1. DApp需要支持TokenPocket 移动端和插件端。</a>
        <a style="margin-top: 14px;">2. 项目方提供的网址可公开访问且稳定性有保证。</a>
        <a style="margin-top: 14px;">3. 智能合约已部署到主网上，敏感逻辑处需开源。</a>
        <a style="margin-top: 14px;">4. 敏感的合约需提供第三方安全机构的审计报告。</a>
        <a style="margin-top: 14px;">5. 交互逻辑清晰，有实际用途，且已适配移动端。</a>
        <a style="margin-top: 14px;">6. 符合相关法律法规，不存在欺诈和侵权等行为。</a>
        <a style="margin-top: 14px;">7. 如违反相关法律法规，自愿承受相应法律责任。</a>
      </div>
      <div style="margin-top: 45px;">
        <a style="font-family: Roboto, Roboto;font-weight: bold;font-size: 17px;color: rgba(0,0,0,0.85);
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;">联系我们</a>
      </div>
      <div style="margin-top: 15px;">
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: rgba(0,0,0,0.85);line-height: 22px;text-align: left;
        font-style: normal;text-transform: none;">✉️ Email:<a style="color: #2980FE">dapp@tokenpocket.pro</a></a>
      </div>
    </div>
  </div>
  <br>
  <br>
</template>
<script>
export default {
  data () {
    return {
      checked: ['1'],
      checked2: ['1'],
      value: '',
      value2: '',
      value3: '',
      value4: '',
      value5: '',
    }
  },
  name: "dappIndex",
}
</script>

<style>
</style>