<template>
  <div id="app1">
    <div style="background-color: #ffffff;height: 80px;max-width: 100%;box-sizing: border-box; display: flex; justify-content: center; 
    align-items: center;position: fixed;top: 0; width: 100vw; z-index: 999;">
      <div style="display: flex; width: 100%; padding: 0 14px;">
        <div style="flex: 1;">
          <van-image @click="goToHome"
                     src="img/logoH5.png"
                     style="width: 156px;"></van-image>
        </div>
        <div style="flex: 1; text-align: right;">
          <van-image @click="togglePopup"
                     v-if="showSantiaogangImg"
                     src="img/santiaogang.png"></van-image>
          <van-image @click="closePopup"
                     v-if="showCloseImg"
                     src="img/close.png"
                     style="width: 16px;height: 16px;"></van-image>
        </div>
      </div>
    </div>
    <div v-if="showPopup">
      <div style="width: 100%;margin-top: 75px;display: flex; flex-direction: column; align-items: center;">
        <van-collapse v-model="activeNames"
                      accordion
                      style="width: 100%;">
          <van-collapse-item title="产品"
                             name="1">
            <div style="margin-left: 15px;display: flex; flex-direction: column;">
              <a style="width: 80%;color: #101010;"
                 @click="goToHome">手机钱包</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://www.keypal.pro/?locale=zh#/"
                 target="_blank">硬件钱包</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://extension.tokenpocket.pro/?locale=zh"
                 target="_blank">插件钱包</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://transit.finance/"
                 target="_blank">Transit</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://www.5degrees.io/?locale=zh#/"
                 target="_blank">5Degrees</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://buy.transit.finance/?locale=zh"
                 target="_blank">购买数字资产</a>
            </div>
          </van-collapse-item>
          <van-collapse-item title="工具"
                             name="2">
            <div style="margin-left: 15px;display: flex; flex-direction: column;">
              <a style="width: 80%;color: #101010;"
                 href="https://verify.tokenpocket.pro/?locale=zh#/"
                 target="_blank">版本校验</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://approval.tokenpocket.pro/?locale=zh#/?address=&chain_id=56"
                 target="_blank">授权检测</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://tokensecurity.tokenpocket.pro/?locale=zh#/"
                 target="_blank">代币安全检测</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://batchsender.tokenpocket.pro/?locale=zh#/"
                 target="_blank">批量转账</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://profile.tokenpocket.pro/?locale=zh#/"
                 target="_blank">Token Profile</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://claim.tokenpocket.pro/?locale=zh#/"
                 target="_blank">Token Claim</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://tp-lab.tokenpocket.pro/insc/?locale=zh#/"
                 target="_blank">铭文助手</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://tp-lab.tokenpocket.pro/nostr/?locale=zh#/"
                 target="_blank">Nostr批量转账</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://key.tokenpocket.pro/?locale=zh#/?network=BTC"
                 target="_blank">私钥生成器</a>
            </div>
          </van-collapse-item>
          <van-collapse-item title="项目合作"
                             name="3">
            <div style="margin-left: 15px;display: flex; flex-direction: column;">
              <a style="width: 80%;color: #101010;"
                 @click="goDapp">提交DApp</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 @click="goToken">提交代币</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 @click="goNtf">提交NFT</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://github.com/TP-Lab/networklist-org"
                 target="_blank">提交公链</a>
            </div>
          </van-collapse-item>
          <van-collapse-item title="社区"
                             name="4">
            <div style="margin-left: 15px;display: flex; flex-direction: column;">
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 href="https://help.tokenpocket.pro/developer-cn"
                 target="_blank">开发者</a>
              <a style="width: 80%;color: #101010;margin-top: 15px;"
                 @click="goRecruiting">TP侠</a>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div style="width: 100%;padding: 16px;"
             href="https://help.tokenpocket.pro/cn">
          <a style="margin-left: 16px;color: #101010;font-size: 15px;display: block;width: 95%;"
             href="https://help.tokenpocket.pro/cn"
             target="_blank">帮助中心</a>
        </div>
      </div>
    </div>
    <div v-if="showView">
      <router-view></router-view>
      <div style="width: 100%;background: #1C1C1F;display: flex; flex-direction: column; align-items: center;">
        <div style="width: 332px;margin-top: 62px;">
          <van-image style="width: 199px;height: 35.5px;"
                     src="img/logo-w.7f84b51.png"></van-image>
          <div style="margin-top: 135px;margin-left: 8px;display: flex; flex-direction: column;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #6C6E71;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;">功能</a>
            <div style="display: flex; flex-direction: column;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;
        color: #FFFFFF;line-height: 20px;text-align: left;font-style: normal;text-transform: none;">
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://buy.transit.finance/?locale=zh"
                 target="_blank">购买加密资产</a>
              <a style="margin-top: 14px;"
                 @click="goToHome">手机钱包</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://www.keypal.pro/?locale=zh#/"
                 target="_blank">硬件钱包</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://extension.tokenpocket.pro/?locale=zh#/"
                 target="_blank">插件钱包</a>
              <a style="margin-top: 14px;">桌面端钱包</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://www.5degrees.io/?locale=zh#/"
                 target="_blank">5Degrees</a>
            </div>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #6C6E71;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;margin-top: 19px;">工具</a>
            <div style="display: flex; flex-direction: column;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;
        color: #FFFFFF;line-height: 20px;text-align: left;font-style: normal;text-transform: none;">
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://verify.tokenpocket.pro/?locale=zh#/"
                 target="_blank">版本校验</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://approval.tokenpocket.pro/?locale=zh#/?address=&chain_id=56"
                 target="_blank">授权检测</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://tokensecurity.tokenpocket.pro/?locale=zh#/"
                 target="_blank">代币安全检测</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://key.tokenpocket.pro/?locale=zh#/?network=BTC"
                 target="_blank">私钥生成器</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://batchsender.tokenpocket.pro/?locale=zh#/"
                 target="_blank">批量转账</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://tp-lab.tokenpocket.pro/nostr/?locale=zh#/"
                 target="_blank">Nostr批量转账</a>
            </div>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #6C6E71;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;margin-top: 19px;">信息</a>
            <div style="display: flex; flex-direction: column;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;
        color: #FFFFFF;line-height: 20px;text-align: left;font-style: normal;text-transform: none;">
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="hhttps://github.tokenpocket.pro/BlockchainGuideSeries/#/"
                 target="_blank">区块链小白书</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://github.tokenpocket.pro/BlockchainGuide-TRON/#/"
                 target="_blank">波场小白书</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://github.tokenpocket.pro/BlockchainGuide-IOST/#/"
                 target="_blank">IOST小白书</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 @click="goRecruiting">TP侠</a>
            </div>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #6C6E71;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;margin-top: 19px;">开发者</a>
            <div style="display: flex; flex-direction: column;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;
        color: #FFFFFF;line-height: 20px;text-align: left;font-style: normal;text-transform: none;">
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://github.com/TP-Lab"
                 target="_blank">Github (TP-Lab)</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://help.tokenpocket.pro/developer-cn"
                 target="_blank">开发者中心</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 @click="goDapp">提交DApp</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 @click="goToken">提交代币</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 @click="goNtf">提交NFT</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://bugrap.io/bounties/TokenPocket"
                 target="_blank">漏洞赏金</a>
            </div>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #6C6E71;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;margin-top: 19px;">公司</a>
            <div style="display: flex; flex-direction: column;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;
        color: #FFFFFF;line-height: 20px;text-align: left;font-style: normal;text-transform: none;">
              <a style="margin-top: 14px;color: #FFFFFF;"
                 @click="goAbout">关于</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://help.tpwallet.io/cn/contact-us/joinus"
                 target="_blank">招聘</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://hilarious-eucalyptus-a2f.notion.site/TokenPocket-Brand-Resources-ab6e6019d20342eea025ec62955084fc"
                 target="_blank">品牌素材</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://shop95838799.m.youzan.com/v2/showcase/homepage?alias=sptfzUCg0j"
                 target="_blank">周边商店</a>
            </div>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #6C6E71;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;margin-top: 19px;">支持</a>
            <div style="display: flex; flex-direction: column;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;
        color: #FFFFFF;line-height: 20px;text-align: left;font-style: normal;text-transform: none;">
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://help.tokenpocket.pro/cn"
                 target="_blank">帮助中心</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://help.tokenpocket.pro/cn/contact-us/contact-methods"
                 target="_blank">联系我们</a>
            </div>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #6C6E71;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;margin-top: 19px;">法律</a>
            <div style="display: flex; flex-direction: column;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;
        color: #FFFFFF;line-height: 20px;text-align: left;font-style: normal;text-transform: none;">
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://www.tokenpocket.pro/privacy-zh/index.html"
                 target="_blank">隐私策略</a>
              <a style="margin-top: 14px;color: #FFFFFF;"
                 href="https://www.tokenpocket.pro/terms-zh/index.html"
                 target="_blank">服务协议</a>
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="app2">
    <div style="width: 100%;height: 130px;display: flex; flex-direction: column; align-items: center;background-color: #ffffff;
    position: fixed;top: 0; width: 100vw; z-index: 999;">
      <div style="width: 100%;display: flex; justify-content: center;padding: 14px;background-color: #fff7e5;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #ff7114;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">根据用户属地相关的监管政策，TokenPocket 已限制中国境内用户访问、使用个别功能</a>
      </div>
      <div style="width: 1245px;height: 71px;display: flex;  align-items: center;">
        <div style="display: flex;  align-items: center;">
          <van-image style="margin-left: 78px;margin-right: 34px;width: 175px;height: 19.73px;cursor: pointer"
                     src="img/pc/pcLogoOld.png"
                     @click="goToHome"></van-image>
          <div @mouseenter="showDropdown"
               @mouseleave="hideDropdown"
               style="cursor: pointer;display: flex;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #414141;line-height: 30px;
            text-align: center;font-style: normal;text-transform: none;">产品</a>
            <div style="margin-top: 10px"><van-image src="img/pc/home/xjt.png"></van-image></div>
          </div>
          <div class="cpDropdown-content"
               v-show="isDropdownVisible"
               @mouseenter="showDropdown"
               @mouseleave="hideDropdown">
            <div style="display: flex; justify-content: center;flex-direction: column; align-items: center;">
              <div style="display: flex;align-items: center;margin-top: 15px;">
                <div style="display: flex;align-items: center;">
                  <div>
                    <van-image src="img/pc/xl/cp_xl1.png"></van-image>
                  </div>
                  <div style="display: flex;flex-direction: column;margin-left: 17px;cursor: pointer"
                       @click="goToHome">
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">手机钱包</a>
                    <a style="width: 211px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #7A7A7A;
                  line-height: 16px;text-align: left;font-style: normal;text-transform: none;">您的移动端数字资产钱包，支持iOS和Android</a>
                  </div>
                </div>
                <a href="https://www.keypal.pro/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;margin-left: 12px;">
                    <div>
                      <van-image src="img/pc/xl/cp_xl2.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;cursor: pointer">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">硬件钱包</a>
                      <a style="width: 211px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #7A7A7A;
                  line-height: 16px;text-align: left;font-style: normal;text-transform: none;">拥有KeyPal，让您的资产更安全</a>
                    </div>
                  </div>
                </a>
              </div>
              <div style="display: flex;align-items: center;margin-top: 30px;">
                <a href="https://extension.tokenpocket.pro/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/cp_xl3.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">插件钱包</a>
                      <a style="width: 211px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #7A7A7A;
                  line-height: 16px;text-align: left;font-style: normal;text-transform: none;">您电脑上更好用的钱包</a>
                    </div>
                  </div>
                </a>
                <a href="https://www.transit.finance/#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;margin-left: 12px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/cp_xl4.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">Transit</a>
                      <a style="width: 211px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #7A7A7A;
                  line-height: 16px;text-align: left;font-style: normal;text-transform: none;">多链聚合、跨链工具及NFT市场</a>
                    </div>
                  </div>
                </a>
              </div>
              <div style="display: flex;align-items: center;margin-top: 30px;">
                <a href="https://www.5degrees.io/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/cp_xl5.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">5Degrees</a>
                      <a style="width: 211px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #7A7A7A;
                  line-height: 16px;text-align: left;font-style: normal;text-transform: none;">Web3.0上的社交网络协议</a>
                    </div>
                  </div>
                </a>
                <a href="https://buy.transit.finance/?locale=zh"
                   target="_blank">
                  <div style="display: flex;align-items: center;margin-left: 12px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/cp_xl6.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">购买数字资产</a>
                      <a style="width: 211px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #7A7A7A;
                  line-height: 16px;text-align: left;font-style: normal;text-transform: none;">通过信用卡等方式购买数字资产</a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div style="margin-left: 30px;cursor: pointer;display: flex;"
               @mouseenter="showDropdown1"
               @mouseleave="hideDropdown1">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #414141;line-height: 30px;
            text-align: center;font-style: normal;text-transform: none;">工具</a>
            <div style="margin-top: 10px"><van-image src="img/pc/home/xjt.png"></van-image></div>
          </div>
          <div class="gjDropdown-content"
               v-show="isDropdownVisible1"
               @mouseenter="showDropdown1"
               @mouseleave="hideDropdown1">
            <div style="display: flex; justify-content: center;flex-direction: column; align-items: center;">
              <div style="display: flex;align-items: center;margin-top: 15px;">
                <a href="https://verify.tokenpocket.pro/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;width: 170px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/gj_xl1.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">版本校验</a>
                    </div>
                  </div>
                </a>
                <a href="https://approval.tokenpocket.pro/?locale=zh#/?address=&chain_id=56"
                   target="_blank">
                  <div style="display: flex;align-items: center;width: 170px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/gj_xl2.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">授权检测</a>
                    </div>
                  </div>
                </a>
              </div>
              <div style="display: flex;align-items: center;margin-top: 15px;">
                <a href="https://tokensecurity.tokenpocket.pro/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;width: 170px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/gj_xl3.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">代币安全检测</a>
                    </div>
                  </div>
                </a>
                <a href="https://batchsender.tokenpocket.pro/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;width: 170px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/gj_xl4.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">批量转账</a>
                    </div>
                  </div>
                </a>
              </div>
              <div style="display: flex;align-items: center;margin-top: 15px;">
                <a href="https://profile.tokenpocket.pro/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;width: 170px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/gj_xl5.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">Token Profile</a>
                    </div>
                  </div>
                </a>
                <a href="https://claim.tokenpocket.pro/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;width: 170px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/gj_xl6.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">Token Claim</a>
                    </div>
                  </div>
                </a>
              </div>
              <div style="display: flex;align-items: center;margin-top: 15px;">
                <a href="https://tp-lab.tokenpocket.pro/insc/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;width: 170px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/gj_xl7.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">铭文助手</a>
                    </div>
                  </div>
                </a>
                <a href="https://tp-lab.tokenpocket.pro/nostr/?locale=zh#/"
                   target="_blank">
                  <div style="display: flex;align-items: center;width: 170px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/gj_xl8.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">Nostr批量转账</a>
                    </div>
                  </div>
                </a>
              </div>
              <div style="display: flex;align-items: center;margin-top: 15px;">
                <a href="https://key.tokenpocket.pro/?locale=zh#/?network=BTC"
                   target="_blank">
                  <div style="display: flex;align-items: center;width: 170px;cursor: pointer">
                    <div>
                      <van-image src="img/pc/xl/gj_xl9.png"></van-image>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 17px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">私钥生成器</a>
                    </div>
                  </div>
                </a>
                <div style="display: flex;align-items: center;width: 170px;">
                </div>
              </div>
            </div>
          </div>
          <div style="margin-left: 30px;cursor: pointer;display: flex;"
               @mouseenter="showDropdown2"
               @mouseleave="hideDropdown2">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #414141;line-height: 30px;
            text-align: center;font-style: normal;text-transform: none;">项目合作</a>
            <div style="margin-top: 10px"><van-image src="img/pc/home/xjt.png"></van-image></div>
          </div>
          <div class="xmhzDropdown-content"
               v-show="isDropdownVisible2"
               @mouseenter="showDropdown2"
               @mouseleave="hideDropdown2">
            <div style="display: flex; justify-content: center;flex-direction: column; align-items: center;">
              <div style="display: flex;align-items: center;margin-top: 15px;margin-left: 8px;cursor: pointer"
                   @click="goDapp">
                <div><van-image src="img/pc/xl/xmhz_xl1.png"></van-image></div>
                <div style="margin-left: 15px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">提交DApp</a>
                </div>
              </div>
              <div style="display: flex;align-items: center;margin-top: 15px;cursor: pointer"
                   @click="goToken">
                <div><van-image src="img/pc/xl/xmhz_xl2.png"></van-image></div>
                <div style="margin-left: 15px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">提交代币</a>
                </div>
              </div>
              <div style="display: flex;align-items: center;margin-top: 15px;cursor: pointer"
                   @click="goNtf">
                <div><van-image src="img/pc/xl/xmhz_xl3.png"></van-image></div>
                <div style="margin-left: 15px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">提交NFT</a>
                </div>
              </div>
              <a href="https://github.com/TP-Lab/networklist-org"
                 target="_blank">
                <div style="display: flex;align-items: center;margin-top: 15px;cursor: pointer">
                  <div><van-image src="img/pc/xl/xmhz_xl4.png"></van-image></div>
                  <div style="margin-left: 15px;">
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">提交公链</a>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div style="margin-left: 30px;cursor: pointer;display: flex;"
               @mouseenter="showDropdown3"
               @mouseleave="hideDropdown3">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #414141;line-height: 30px;
            text-align: center;font-style: normal;text-transform: none;">社区</a>
            <div style="margin-top: 10px"><van-image src="img/pc/home/xjt.png"></van-image></div>
          </div>
          <div class="sqDropdown-content"
               v-show="isDropdownVisible3"
               @mouseenter="showDropdown3"
               @mouseleave="hideDropdown3">
            <div style="display: flex; justify-content: center;flex-direction: column; align-items: center;">
              <a href="https://help.tokenpocket.pro/developer-cn"
                 target="_blank">
                <div style="display: flex;align-items: center;margin-top: 15px;margin-left: 8px;cursor: pointer">
                  <div><van-image src="img/pc/xl/sq_xl1.png"></van-image></div>
                  <div style="margin-left: 15px;">
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">开发者</a>
                  </div>
                </div>
              </a>
              <div style="display: flex;align-items: center;margin-top: 15px;cursor: pointer"
                   @click="goRecruiting">
                <div><van-image src="img/pc/xl/sq_xl2.png"></van-image></div>
                <div style="margin-left: 15px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A3B3D;
                  line-height: 30px;text-align: left;font-style: normal;text-transform: none;">TP侠</a>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-left: 30px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #414141;line-height: 30px;
            text-align: center;font-style: normal;text-transform: none;"
               href="https://help.tokenpocket.pro/cn/"
               target="_blank">帮助中心</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showView">
      <router-view></router-view>
    </div>
    <div style="width: 100%;background: #1C1C1F;height: 642px;display: flex; flex-direction: column; align-items: center;">
      <div style="display: flex;width: 1235px;">
        <div style="display: flex; flex-direction: column;margin-left: 70px;margin-top: 68px;">
          <van-image style="width: 194px;height: 34px;"
                     src="img/pc/pcDbLogo.svg"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #5C5E61;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;margin-left: 20px;margin-top: 71px;">@TokenPocket Foundation Ltd.</a>
        </div>
        <div style="display: flex; flex-direction: column;margin-left: 219px;margin-top: 72px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #6C6E71;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">功能</a>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #FFFFFF;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://buy.transit.finance/?locale=zh">购买加密资产</a>
            <a style="margin-top: 13px;cursor: pointer"
               @click="goToHome">手机钱包</a>
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://www.keypal.pro/?locale=zh#/">硬件钱包</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://extension.tokenpocket.pro/?locale=zh">插件钱包</a>
            <a style="margin-top: 22px;">桌面端钱包</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://www.5degrees.io/?locale=zh#/">5Degrees</a>
          </div>
        </div>
        <div style="display: flex; flex-direction: column;margin-left: 44px;margin-top: 72px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #6C6E71;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">工具</a>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #FFFFFF;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://verify.tokenpocket.pro/?locale=zh#/">版本校验</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://approval.tokenpocket.pro/?locale=zh">授权检测</a>
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://tokensecurity.tokenpocket.pro/?locale=zh">代币安全检测</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://profile.tokenpocket.pro/?locale=zh#/">Token Profile</a>
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://claim.tokenpocket.pro/?locale=zh#/">Token Claim</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://key.tokenpocket.pro/?locale=zh#/">私钥生成器</a>
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://key.tokenpocket.pro/?locale=zh#/">批量转账</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://tp-lab.tokenpocket.pro/nostr/?locale=zh#/">Nostr批量转账</a>
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://tp-lab.tokenpocket.pro/insc/?locale=zh#/">铭文助手</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://rex.tokenpocket.pro/?locale=zh">REX</a>
          </div>
        </div>
        <div style="display: flex; flex-direction: column;margin-left: 41px;margin-top: 72px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #6C6E71;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">信息</a>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #FFFFFF;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://github.tokenpocket.pro/BlockchainGuideSeries/#/">区块链小白书</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://github.tokenpocket.pro/BlockchainGuide-TRON/#/">波场小白书</a>
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://github.tokenpocket.pro/BlockchainGuide-IOST/#/">IOST小白书</a>
            <a style="margin-top: 13px;cursor: pointer"
               @click="goRecruiting">TP侠</a>
          </div>
        </div>
        <div style="display: flex; flex-direction: column;margin-left: 44px;margin-top: 72px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #6C6E71;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">开发者</a>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #FFFFFF;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a style="margin-top: 22px;color:#FFFFFF;"
               href="https://github.com/TP-Lab">Github (TP-Lab)</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://help.tokenpocket.pro/developer-cn/">开发者中心</a>
            <a style="margin-top: 22px;cursor: pointer"
               @click="goDapp">提交DApp</a>
            <a style="margin-top: 13px;cursor: pointer"
               @click="goToken">提交代币</a>
            <a style="margin-top: 22px;cursor: pointer"
               @click="goNtf">提交NFT</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://bugrap.io/bounties/TokenPocket">漏洞赏金</a>
          </div>
        </div>
        <div style="display: flex; flex-direction: column;margin-left: 48px;margin-top: 72px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #6C6E71;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">公司</a>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #FFFFFF;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a style="margin-top: 22px;cursor: pointer"
               @click="goAbout">关于</a>
            <a style="margin-top: 13px;color:#FFFFFF;"
               href="https://help.tpwallet.io/cn/contact-us/joinus">招聘</a>
            <a style="margin-top: 22px;color: #FFFFFF;"
               href="https://hilarious-eucalyptus-a2f.notion.site/TokenPocket-Brand-Resources-ab6e6019d20342eea025ec62955084fc">品牌素材</a>
            <a style="margin-top: 13px;color: #FFFFFF;"
               href="https://shop95838799.m.youzan.com/v2/showcase/homepage?alias=sptfzUCg0j">周边商店</a>
          </div>
        </div>
        <div style="display: flex; flex-direction: column;margin-left: 48px;margin-top: 72px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #6C6E71;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">支持</a>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #FFFFFF;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a style="margin-top: 22px;color: #FFFFFF;"
               href="https://help.tokenpocket.pro/cn/">帮助中心</a>
            <a style="margin-top: 13px;color: #FFFFFF;"
               href="https://help.tokenpocket.pro/cn/contact-us/contact-methods">联系我们</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #6C6E71;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">法律</a>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #FFFFFF;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a style="margin-top: 22px;color: #FFFFFF;"
               href="https://www.tokenpocket.pro/privacy-zh/index.html">隐私策略</a>
            <a style="margin-top: 13px;color: #FFFFFF;"
               href="https://www.tokenpocket.pro/terms-zh/index.html">服务协议</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showSantiaogangImg: true,
      showView: true,
      showCloseImg: false,
      showPopup: false,
      activeNames: ['1'],
      isDropdownVisible: false,
      isDropdownVisible1: false,
      isDropdownVisible2: false,
      isDropdownVisible3: false,
    };
  },
  name: "homeIndex",
  methods: {
    goToHome () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goAbout () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/about' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goToken () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/token' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goNtf () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/ntf' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goDapp () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/dapp' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goRecruiting () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/recruiting' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    togglePopup () {
      this.showSantiaogangImg = false;
      this.showCloseImg = true;
      this.showPopup = true;
      this.showView = false;
      window.scrollTo(0, 0);
      // 处理弹窗显示的额外逻辑
    },
    closePopup () {
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      window.scrollTo(0, 0);
      // 处理关闭弹窗的额外逻辑
    },
    showDropdown () {
      this.isDropdownVisible = true;
    },
    hideDropdown () {
      this.isDropdownVisible = false;
    },
    showDropdown1 () {
      this.isDropdownVisible1 = true;
    },
    hideDropdown1 () {
      this.isDropdownVisible1 = false;
    },
    showDropdown2 () {
      this.isDropdownVisible2 = true;
    },
    hideDropdown2 () {
      this.isDropdownVisible2 = false;
    },
    showDropdown3 () {
      this.isDropdownVisible3 = true;
    },
    hideDropdown3 () {
      this.isDropdownVisible3 = false;
    }
  },
}
function isMobileDevice () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

function toggleDivDisplay () {
  if (isMobileDevice()) {
    document.getElementById('app1').style.display = 'block'
    document.getElementById('app2').style.display = 'none'
  } else {
    document.getElementById('app1').style.display = 'none'
    document.getElementById('app2').style.display = 'block'
  }
}

window.onresize = toggleDivDisplay
window.onload = toggleDivDisplay
</script>

<style scoped>
.cpDropdown-content {
  position: absolute;
  background: #ffffff;
  width: 612px;
  height: 257px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  z-index: 1;
  padding: 10px;
  top: calc(75% + 10px);
  left: 40%;
  transform: translateX(-50%);
}
.gjDropdown-content {
  position: absolute;
  background: #ffffff;
  width: 380px;
  height: 291px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  z-index: 1;
  padding: 10px;
  top: calc(75% + 10px);
  left: 38%;
  transform: translateX(-50%);
}
.xmhzDropdown-content {
  position: absolute;
  background: #ffffff;
  width: 150px;
  height: 220px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  z-index: 1;
  padding: 10px;
  top: calc(75% + 10px);
  left: 41%;
  transform: translateX(-50%);
}
.sqDropdown-content {
  position: absolute;
  background: #ffffff;
  width: 130px;
  height: 110px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  z-index: 1;
  padding: 10px;
  top: calc(75% + 10px);
  left: 46%;
  transform: translateX(-50%);
}
</style>