<template>
  <div style="display: flex; flex-direction: column; align-items: center;margin-top: 150px;">
    <div style="width: 1244px;height: 562px;background-image: url('img/pc/home/pcHomeDt1.svg');">
      <div style="margin-left: 79px;">
        <div style="margin-top: 198px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 50px;color: #43454F;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;">让区块链随处发生</a>
        </div>
        <div style="margin-top: 20px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #818181;
      line-height: 36px;text-align: left;font-style: normal;text-transform: none;">让你安全、简单地探索区块链，是全球领先的数字资产钱包</a>
        </div>
        <div style="margin-top: 40px;display: flex; align-items: center;">
          <van-button class="buttonPc"
                      id="installButtonPc"
                      type="primary"
                      color="#2A80EA"
                      style="width: 190px;height: 60px;"
                      @click="goPcDownloads"
                      round>
            <div style="display: flex;align-items: center;">
              <van-image src="img/h5/buttonMsg1.png"></van-image>
              <a style="font-family: Inter, Inter;font-weight: bold;font-size: 16px;color: #FFFFFF;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">下载</a>
            </div>
          </van-button>
          <van-button type="primary"
                      style="width: 190px;height: 60px;margin-left: 20px;"
                      url="https://www.keypal.pro/?locale=zh#/"
                      round
                      plain
                      hairline>
            <div style="display: flex;align-items: center;">
              <van-image src="img/h5/buttonMsg2.png"></van-image>
              <a style="font-family: Inter, Inter;font-weight: 500;font-size: 16px;color: #3A3B3D;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">KeyPal</a>
            </div>
          </van-button>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 430px;background: #3D8CFE;margin-top: 62px;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 33px;color: #FFFFFF;
      line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 74px;">TokenPocket深受全球用户信赖</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #BBD5FF;
      line-height: 20px;text-align: center;font-style: normal;text-transform: none;margin-top: 16px;">我们为全球200多个国家及地区的用户提供安全易用的数字资产服务</a>
      <div style="display: flex; align-items: center;margin-top: 49px;">
        <div style="width: 341px;height: 170px;border-radius: 13px 13px 13px 13px;border: 1px solid #7BAFFF;
        display: flex; flex-direction: column; align-items: center;">
          <van-image style="margin-top: 24px;"
                     src="img/h5/home/homext1.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">服务的用户</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 30px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 11px;">20M+</a>
        </div>
        <div style="width: 341px;height: 170px;border-radius: 13px 13px 13px 13px;border: 1px solid #7BAFFF;
        display: flex; flex-direction: column; align-items: center;margin-left: 28px;">
          <van-image style="margin-top: 24px;"
                     src="img/h5/home/homext2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">日均交易数</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 30px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 11px;">30M+</a>
        </div>
        <div style="width: 341px;height: 170px;border-radius: 13px 13px 13px 13px;border: 1px solid #7BAFFF;
        display: flex; flex-direction: column; align-items: center;margin-left: 28px;">
          <van-image style="margin-top: 24px;"
                     src="img/h5/home/homext3.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
          line-height: 28px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">国家及地区</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 30px;color: #FFFFFF;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 11px;">200+</a>
        </div>
      </div>
    </div>
    <div style="margin-top: 142px;width: 1047px;">
      <div style="display: flex;">
        <van-image style="margin-top: 24px;width: 473px;height: 405px;"
                   src="img/pc/home/pcHomeDt2.svg"></van-image>
        <div style="width: 430px;height: 405px;margin-left: 150px;display: flex; flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #3A3B3D;line-height: 30px;
            text-align: left;text-align: left;text-transform: none;margin-top: 12px;">安全是我们的根基</a>
          <div style="margin-top: 28px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
            color: #737578;line-height: 24px;text-align: left;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a>TokenPocket只在用户的设备里生成并存储私钥助记词，您是唯一可以访问的人</a>
            <a style="margin-top: 20px;">TokenPocket同时开发了冷钱包、多签钱包等，来满足您对安全的需求</a>
            <a style="margin-top: 20px;">多链钱包，支持BTC、ETH、BSC、TRON、Aptos、Polygon、Solana、Cosmos、Polkadot、EOS、IOST等</a>
          </div>
          <div style="margin-top: 56px;display: flex; align-items: center;">
            <van-button class="buttonPc"
                        id="installButtonPc"
                        type="primary"
                        color="#3D3E40"
                        style="width: 158px;height: 53px;"
                        @click="goPcDownloads"
                        round
                        plain
                        hairline>
              <div style="display: flex;align-items: center;">
                <van-image src="img/pc/home/buttonImg1.png"></van-image>
                <a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;color: #3D3E40;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">下载</a>
              </div>
            </van-button>
            <van-button type="primary"
                        color="#3D3E40"
                        style="width: 158px;height: 53px;margin-left: 20px;"
                        url="https://www.keypal.pro/?locale=zh#/"
                        round
                        plain
                        hairline>
              <div style="display: flex;align-items: center;">
                <van-image src="img/h5/buttonMsg2.png"></van-image>
                <a style="font-family: Inter, Inter;font-weight: 500;font-size: 14px;color: #3D3E40;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">KeyPal</a>
              </div>
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 140px;width: 1082px;">
      <div style="display: flex;">
        <div style="width: 473px;height: 366px;margin-top: 66px;display: flex; flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #3A3B3D;
            line-height: 30px;text-align: left;font-style: normal;text-transform: none;">兑换、交易更简单</a>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #737578;
            line-height: 34px;text-align: left;font-style: normal;text-transform: none;margin-top: 23px;display: flex; flex-direction: column;">
            <a>随时随地，让你更方便地交易</a>
            <a>用你的信用卡购入数字资产，轻松存储、发送、跨链与兑换</a>
          </div>
          <div style="width: 421px;height: 70px;border-radius: 16px 16px 16px 16px;border: 1px solid #E6E8EA;margin-top: 30px;display: flex;align-items: center;">
            <a href="https://swap.transit.finance/?locale=zh#/"
               target="_blank"
               style="display: inline-block;">
              <div style="display: flex; flex-direction: column; align-items: center; margin-left: 35px;">
                <span style="font-family: PingFang HK, PingFang HK; font-weight: 600; font-size: 15px; color: #3A3B3D;
        line-height: 24px; text-align: left; font-style: normal; text-transform: none;">闪兑</span>
                <span style="font-family: PingFang HK, PingFang HK; font-weight: 500; font-size: 13px; color: #9EA0A6;
        line-height: 24px; text-align: right; font-style: normal; text-transform: none;">简单快速 ></span>
              </div>
            </a>
            <div style="width: 0px;height: 46px;border-radius: 1px 1px 1px 1px;border: 1px solid #E6E8EA;margin-left: 34px;margin-right: 34px;"></div>
            <a href="https://swap.transit.finance/?locale=zh&inputChain=ETH&inputSymbol=ETH#/"
               target="_blank"
               style="display: inline-block;">
              <div style="display: flex; flex-direction: column; align-items: center;">
                <span style="font-family: PingFang HK, PingFang HK; font-weight: 600; font-size: 15px; color: #3A3B3D;
       line-height: 24px; text-align: left; font-style: normal; text-transform: none;">跨链</span>
                <span style="font-family: PingFang HK, PingFang HK; font-weight: 500; font-size: 13px; color: #9EA0A6;
       line-height: 24px; text-align: right; font-style: normal; text-transform: none;">多链转换 ></span>
              </div>
            </a>
            <div style="width: 0px;height: 46px;border-radius: 1px 1px 1px 1px;border: 1px solid #E6E8EA;margin-left: 34px;margin-right: 34px;"></div>
            <a href="https://openc.pro/widget-page/?widgetId=UjcyR3hQVlk"
               target="_blank"
               style="display: inline-block;">
              <div style="display: flex; flex-direction: column; align-items: center;">
                <span style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
                line-height: 24px;text-align: left;font-style: normal;text-transform: none;">购入数字资产</span>
                <span style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
                line-height: 24px;text-align: right;font-style: normal;text-transform: none;">仅在5分钟内 ></span>
              </div>
            </a>
          </div>
        </div>
        <van-image style="margin-left: 153px;width: 473px;height: 366px;"
                   src="img/pc/home/pcHomeDt3.svg"></van-image>
      </div>
    </div>
    <div style="margin-top: 120px;width: 1047px;">
      <div style="display: flex;">
        <van-image style="width: 473px;height: 342px;"
                   src="img/pc/home/pcHomeDt4.png"></van-image>
        <div style="width: 473px;height: 342px;display: flex; flex-direction: column;margin-left: 114px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #3A3B3D;
            line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 45px;">一站式DApp商店</a>
          <div style="margin-top: 28px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #737578;
            line-height: 24px;text-align: left;font-style: normal;text-transform: none;display: flex; flex-direction: column;">
            <a>找到你最喜爱的去中心化应用，发现最新、最热的DApp，一切尽在TokenPocket</a>
            <a style="margin-top: 20px;">集成DApp浏览器，您可以访问你的任何DApp链接</a>
          </div>
          <div style="width: 450px;height: 70px;border-radius: 16px 16px 16px 16px;border: 1px solid #E6E8EA;margin-top: 30px;display: flex;align-items: center;">
            <div style="display: flex; flex-direction: column; align-items: center;margin-left: 55px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
                line-height: 24px;text-align: left;font-style: normal;text-transform: none;">DeFi</a>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
                line-height: 24px;text-align: right;font-style: normal;text-transform: none;">已支持</a>
            </div>
            <div style="width: 0px;height: 46px;border-radius: 1px 1px 1px 1px;border: 1px solid #E6E8EA;margin-left: 55px;margin-right: 55px;"></div>
            <div style="display: flex; flex-direction: column; align-items: center;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
                line-height: 24px;text-align: left;font-style: normal;text-transform: none;">NFT</a>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
                line-height: 24px;text-align: right;font-style: normal;text-transform: none;">已支持</a>
            </div>
            <div style="width: 0px;height: 46px;border-radius: 1px 1px 1px 1px;border: 1px solid #E6E8EA;margin-left: 55px;margin-right: 55px;"></div>
            <div style="display: flex; flex-direction: column; align-items: center;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 15px;color: #3A3B3D;
                line-height: 24px;text-align: left;font-style: normal;text-transform: none;">DApp</a>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;color: #9EA0A6;
                line-height: 24px;text-align: right;font-style: normal;text-transform: none;">已支持</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 125px;width: 1082px;">
      <div style="display: flex;">
        <div style="width: 473px;height: 342px;display: flex; flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #3A3B3D;
            line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 76px;">社区</a>
          <div style="argin-top: 16px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #737578;
              line-height: 24px;text-align: left;font-style: normal;text-transform: none;width: 461px;margin-top: 16px;">
            <a>我们坚持开放性社区，始终如一！我们欢迎所有开发者一起开发更方便、更丰富的的区块链世界</a>
          </div>
          <div style="width: 450px;height: 70px;border-radius: 16px 16px 16px 16px;border: 1px solid #E6E8EA;margin-top: 37px;display: flex;align-items: center;">
            <a href="https://github.com/TP-Lab"
               target="_blank">
              <div style="display: flex; flex-direction: column; align-items: center;margin-left: 49px;">
                <van-image style="width: 27px;height: 27px;"
                           src="img/pc/home/pcHomeXt1.png"></van-image>
                <span style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 11px;color: #9EA0A6;
                line-height: 24px;line-height: 24px;font-style: normal;text-transform: none;">TP-Lab ></span>
              </div>
            </a>
            <div style="width: 0px;height: 46px;border-radius: 1px 1px 1px 1px;border: 1px solid #E6E8EA;margin-left: 42px;margin-right: 45px;"></div>
            <a href="https://discord.com/invite/NKPM8TXFQk"
               target="_blank">
              <div style="display: flex; flex-direction: column; align-items: center;">
                <van-image style="width: 27px;height: 27px;"
                           src="img/pc/home/pcHomeXt2.png"></van-image>
                <span style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 11px;color: #9EA0A6;
                line-height: 24px;line-height: 24px;font-style: normal;text-transform: none;">开发者社区 ></span>
              </div>
            </a>
            <div style="width: 0px;height: 46px;border-radius: 1px 1px 1px 1px;border: 1px solid #E6E8EA;margin-left: 42px;margin-right: 45px;"></div>
            <a href="https://help.tokenpocket.pro/developer-cn"
               target="_blank">
              <div style="display: flex; flex-direction: column; align-items: center;">
                <van-image style="width: 27px;height: 27px;"
                           src="img/pc/home/pcHomeXt3.png"></van-image>
                <span style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 11px;color: #9EA0A6;
                line-height: 24px;line-height: 24px;font-style: normal;text-transform: none;">开发者文档 ></span>
              </div>
            </a>
          </div>
        </div>
        <van-image style="width: 461px;height: 302px;margin-left: 160px;"
                   src="img/pc/home/pcHomeDt5.png"></van-image>
      </div>
    </div>
    <div style="width: 100%;height: 166px;background: #3D8CFE;margin-top: 156px;display: flex; flex-direction: column; align-items: center;">
      <div style="width: 1056px;">
        <div style="display: flex;">
          <div style="display: flex; flex-direction: column;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #FFFFFF;
            line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 28px;">现在就拥有您的TokenPocket!</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #BBD5FF;
            line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 5px;">安全可信赖的数字资产钱包，伴您探索区块链世界</a>
            <van-button class="buttonPc"
                        id="installButtonPc"
                        type="primary"
                        color="#FFFFFF"
                        style="width: 146px;height: 40px;margin-top: 15px;"
                        @click="goPcDownloads"
                        round>
              <a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;color: #3D8CFE;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 7px;">下载</a>
            </van-button>
          </div>
          <van-image style="width: 262.5px;height: 162.8px;margin-left: 458px;margin-top: 4px;"
                     src="img/pc/home/pcHomeDt6.svg"></van-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homePcIndex",
  methods: {
    goPcDownloads () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/download' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
  }
}
</script>

<style>
</style>