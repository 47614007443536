<template>
  <div style="display: flex; flex-direction: column; align-items: center;margin-top: 150px;">
    <div style="width: 1027px;display: flex; flex-direction: column;">
      <van-image src="img/pc/token/pcTokenDt1.png"></van-image>
      <van-radio-group v-model="checked"
                       direction="horizontal"
                       shape="dot"
                       style="margin-top: 20px;">
        <van-radio name="1"
                   icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">以太坊</a></van-radio>
        <van-radio name="2"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">币安智能链</a></van-radio>
        <van-radio name="3"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">火币生态链</a></van-radio>
        <van-radio name="4"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">OKExChain</a></van-radio>
        <van-radio name="5"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">TRON</a></van-radio>
        <van-radio name="6"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">EOS</a></van-radio>
        <van-radio name="7"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">IOST</a></van-radio>
        <van-radio name="8"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Polkadot</a></van-radio>
        <van-radio name="9"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Polygon</a></van-radio>
        <van-radio name="10"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Klaytn</a></van-radio>
      </van-radio-group>
      <van-radio-group v-model="checked"
                       direction="horizontal"
                       shape="dot"
                       style="margin-top: 17px;">
        <van-radio name="11"
                   icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">COSMOS</a></van-radio>
        <van-radio name="12"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">BNB</a></van-radio>
        <van-radio name="49"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Arbitrum One</a></van-radio>
        <van-radio name="50"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Fantom</a></van-radio>
        <van-radio name="13"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Ethereum Classic</a></van-radio>
        <van-radio name="14"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Conflux eSpace</a></van-radio>
        <van-radio name="15"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Solana</a></van-radio>
        <van-radio name="16"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">AVAX</a></van-radio>
      </van-radio-group>
      <van-radio-group v-model="checked"
                       direction="horizontal"
                       shape="dot"
                       style="margin-top: 17px;">
        <van-radio name="17"
                   icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Optimistic Ethereum</a></van-radio>
        <van-radio name="18"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Gnosis Chain</a></van-radio>
        <van-radio name="19"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Harmony</a></van-radio>
        <van-radio name="20"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">WAX</a></van-radio>
        <van-radio name="21"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Aurora</a></van-radio>
        <van-radio name="22"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Kusama</a></van-radio>
        <van-radio name="23"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Moonbeam</a></van-radio>
        <van-radio name="24"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">KCC Mainnet</a></van-radio>
      </van-radio-group>
      <van-radio-group v-model="checked"
                       direction="horizontal"
                       shape="dot"
                       style="margin-top: 17px;">
        <van-radio name="25"
                   icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">PlatON</a></van-radio>
        <van-radio name="26"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">BTTC</a></van-radio>
        <van-radio name="27"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">GateChain</a></van-radio>
        <van-radio name="28"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">HALO</a></van-radio>
        <van-radio name="29"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Arbirtum Nova</a></van-radio>
        <van-radio name="30"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Aptos</a></van-radio>
        <van-radio name="31"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Filecoin FVM</a></van-radio>
        <van-radio name="32"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">zkSync Era</a></van-radio>
        <van-radio name="33"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">EOS EVM</a></van-radio>
      </van-radio-group>
      <van-radio-group v-model="checked"
                       direction="horizontal"
                       shape="dot"
                       style="margin-top: 17px;">
        <van-radio name="34"
                   icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Mantle</a></van-radio>
        <van-radio name="35"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Linea</a></van-radio>
        <van-radio name="51"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Base</a></van-radio>
        <van-radio name="52"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">opBNB</a></van-radio>
        <van-radio name="53"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Polygon zkEVM</a></van-radio>
        <van-radio name="36"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">CORE</a></van-radio>
        <van-radio name="37"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">HAQQ</a></van-radio>
        <van-radio name="38"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Scroll</a></van-radio>
        <van-radio name="39"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Manta</a></van-radio>
        <van-radio name="40"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">BEVM</a></van-radio>
      </van-radio-group>
      <van-radio-group v-model="checked"
                       direction="horizontal"
                       shape="dot"
                       style="margin-top: 17px;">
        <van-radio name="41"
                   icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">ZKFair</a></van-radio>
        <van-radio name="42"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Metis</a></van-radio>
        <van-radio name="43"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Zeta Chain</a></van-radio>
        <van-radio name="44"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Merlin</a></van-radio>
        <van-radio name="45"
                   icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Blast</a></van-radio>
        <van-radio name="46"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">X Layer</a></van-radio>
        <van-radio name="47"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Bitlayer</a></van-radio>
        <van-radio name="48"
                   icon-size="16px"
                   style="margin-left: 16px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Kroma</a></van-radio>
      </van-radio-group>
      <div style="display: flex;align-items: center;margin-top: 36px;">
        <div style="width: 498px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">代币符号（如：AAA）</a>
        </div>
        <div style="width: 498px;margin-left: 26px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">合约地址（如：itokenpocket）</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 6px;">
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue" />
          </van-cell-group>
        </div>
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;margin-left: 26px;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue2" />
          </van-cell-group>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 28px;">
        <div style="width: 498px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">精度（如：18）</a>
        </div>
        <div style="width: 498px;margin-left: 26px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">代币数量</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 6px;">
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue3" />
          </van-cell-group>
        </div>
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;margin-left: 26px;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue4" />
          </van-cell-group>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 28px;">
        <div style="width: 498px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">项目简介</a>
        </div>
        <div style="width: 498px;margin-left: 26px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">Email</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 6px;">
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue5" />
          </van-cell-group>
        </div>
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;margin-left: 26px;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue6" />
          </van-cell-group>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 28px;">
        <div style="width: 498px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">官方网址（如：https://www.tokenpocket.pro）(0/120)</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 6px;">
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue7" />
          </van-cell-group>
        </div>
      </div>
      <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #616367;line-height: 22px;
      text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">提交代币信息及logo</a>
      <van-button type="primary"
                  style="width: 133px;height: 43px;margin-top: 6px;"
                  color="#2980FE;"
                  url="https://github.com/TP-Lab/tokens"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 11px;
                  color: #FFFFFF;line-height: 22px;text-align: left;font-style: normal;text-transform: none;">跳转至Github提交</a></van-button>
      <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #000000;line-height: 22px;
      text-align: left;font-style: normal;text-transform: none;margin-top: 41px;">联系方式</a>
      <div style="display: flex;align-items: center;margin-top: 20px;">
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 13px;color: #000000;
          line-height: 22px;text-align: left;font-style: normal;text-transform: none;">✉️ Email:<a style="color: #2980FE">dapp@tokenpocket.pro</a></a>
      </div>
      <div style="display: flex;flex-direction: column;align-items: center;margin-top: 31px;margin-bottom: 22px;">
        <van-button type="primary"
                    style="width: 505px;height: 32px;background: #2980FE;">
          <a style="font-family: Roboto, Roboto;font-weight: bold;font-size: 13px;color: #FFFFFF;
          line-height: 22px;text-align: left;font-style: normal;text-transform: none;">提交</a>
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      pcvalue: '',
      pcvalue2: '',
      pcvalue3: '',
      pcvalue4: '',
      pcvalue5: '',
      pcvalue6: '',
      pcvalue7: '',
      checked: ['1'],
    }
  },
  name: "tokenPcIndex",
}
</script>

<style>
</style>