import { createRouter, createWebHistory } from 'vue-router'

import homeIndex from '@/components/homeIndex'
import downloadIndex from '@/components/downloadIndex'
import aboutIndex from '@/components/aboutIndex'
import tokenIndex from '@/components/tokenIndex'
import nftIndex from '@/components/nftIndex'
import dappIndex from '@/components/dappIndex'
import recruitingIndex from '@/components/recruitingIndex'

//Pc
import homePcIndex from '@/componentsPc/homePcIndex'
import aboutPcIndex from '@/componentsPc/aboutPcIndex'
import recruitingPcIndex from '@/componentsPc/recruitingPcIndex'
import downloadPcIndex from '@/componentsPc/downloadPcIndex'
import dappPcIndex from '@/componentsPc/dappPcIndex'
import nftPcIndex from '@/componentsPc/nftPcIndex'
import tokenPcIndex from '@/componentsPc/tokenPcIndex'

let routes
if (isMobileDevice()) {
  routes = [
    {
      path: '/',
      component: homeIndex,
    },
    {
      path: '/download',
      component: downloadIndex,
    },
    {
      path: '/about',
      component: aboutIndex,
    },
    {
      path: '/token',
      component: tokenIndex,
    },
    {
      path: '/ntf',
      component: nftIndex,
    },
    {
      path: '/dapp',
      component: dappIndex,
    },
    {
      path: '/recruiting',
      component: recruitingIndex,
    },
  ]
} else {
  routes = [
    {
      path: '/',
      component: homePcIndex,
    },
    {
      path: '/about',
      component: aboutPcIndex,
    },
    {
      path: '/recruiting',
      component: recruitingPcIndex,
    },
    {
      path: '/download',
      component: downloadPcIndex,
    },
    {
      path: '/dapp',
      component: dappPcIndex,
    },
    {
      path: '/ntf',
      component: nftPcIndex,
    },
    {
      path: '/token',
      component: tokenPcIndex,
    },
  ]
}

const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes,
})

function isMobileDevice() {
  // 根据 User Agent 或其他条件判断是否为移动设备
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

export default router
