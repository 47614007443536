<template>
  <div style="display: flex; flex-direction: column; align-items: center;margin-top: 150px;">
    <div style="width: 1027px;display: flex; flex-direction: column;">
      <van-image src="img/pc/nft/pcNftDt1.png"></van-image>
      <div style="display: flex;align-items: center;margin-top: 44px;">
        <a style="font-family: SimSong, SimSong;font-weight: 400;font-size: 10px;color: #FF4D4F;
        line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
        <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">项目简介</a>
      </div>
      <div style="display: flex;align-items: center;margin-top: 16px;">
        <van-radio-group v-model="pcChecked"
                         direction="horizontal"
                         shape="dot">
          <van-radio name="1"
                     icon-size="16px"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">以太坊</a></van-radio>
          <van-radio name="2"
                     icon-size="16px"
                     style="margin-left: 26px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">币安智能链</a></van-radio>
          <van-radio name="3"
                     icon-size="16px"
                     style="margin-left: 26px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">火币生态链</a></van-radio>
          <van-radio name="4"
                     icon-size="16px"
                     style="margin-left: 26px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Polygon</a></van-radio>
          <van-radio name="5"
                     icon-size="16px"
                     style="margin-left: 26px;"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #636569;
                   line-height: 22px;text-align: left;font-style: normal;text-transform: none;">Fantom</a></van-radio>
        </van-radio-group>
      </div>
      <div style="display: flex;align-items: center;margin-top: 36px;">
        <div style="width: 498px;">
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">代币符号（如：AAA）</a>
        </div>
        <div style="width: 498px;margin-left: 26px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">合约地址（如：itokenpocket）</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 6px;">
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue" />
          </van-cell-group>
        </div>
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;margin-left: 26px;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue2" />
          </van-cell-group>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 28px;">
        <div style="width: 498px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">代币数量</a>
        </div>
        <div style="width: 498px;margin-left: 26px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">项目简介</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 6px;">
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue3" />
          </van-cell-group>
        </div>
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;margin-left: 26px;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue4" />
          </van-cell-group>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 28px;">
        <div style="width: 498px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">Email</a>
        </div>
        <div style="width: 498px;margin-left: 26px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">官方网址（如：https://www.tokenpocket.pro）</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 6px;">
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue5" />
          </van-cell-group>
        </div>
        <div style="width: 498px;height: 38px;border-radius: 4px 4px 4px 4px;border: 1px solid #D9D9D9;margin-left: 26px;">
          <van-cell-group inset
                          style="width: 474px;height: 30px;">
            <van-field v-model="pcvalue6" />
          </van-cell-group>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 28px;">
        <div style="width: 498px;">
          <a style="width: 7px;height: 22px;font-family: SimSong, SimSong;font-weight: 400;font-size: 14px;
        color: #FF4D4F;line-height: 22px;text-align: right;font-style: normal;text-transform: none;">*</a>
          <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #616367;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-left: 4px;">NFT类型</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 6px;">
        <select style="width: 498px;height: 38px;border: 1px solid #D9D9D9;padding: 0 10px;">
          <option>721</option>
          <option>1155</option>
        </select>
      </div>
      <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 14px;color: #616367;line-height: 22px;
      text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">提交代币信息及logo</a>
      <van-button type="primary"
                  style="width: 133px;height: 43px;margin-top: 6px;"
                  color="#2980FE;"
                  url="https://github.com/TP-Lab/tokens"><a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 11px;
                  color: #FFFFFF;line-height: 22px;text-align: left;font-style: normal;text-transform: none;">跳转至Github提交</a></van-button>
      <a style="font-family: Roboto, Roboto;font-weight: 600;font-size: 14px;color: #000000;line-height: 22px;
      text-align: left;font-style: normal;text-transform: none;margin-top: 41px;">联系方式</a>
      <div style="display: flex;align-items: center;margin-top: 20px;">
        <a style="font-family: Roboto, Roboto;font-weight: 400;font-size: 13px;color: #000000;
          line-height: 22px;text-align: left;font-style: normal;text-transform: none;">✉️ Email:<a style="color: #2980FE">dapp@tokenpocket.pro</a></a>
      </div>
      <div style="display: flex;flex-direction: column;align-items: center;margin-top: 31px;margin-bottom: 22px;">
        <van-button type="primary"
                    style="width: 505px;height: 32px;background: #2980FE;">
          <a style="font-family: Roboto, Roboto;font-weight: bold;font-size: 13px;color: #FFFFFF;
          line-height: 22px;text-align: left;font-style: normal;text-transform: none;">提交</a>
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      pcChecked: ['1'],
      pcvalue: '',
      pcvalue2: '',
      pcvalue3: '',
      pcvalue4: '',
      pcvalue5: '',
      pcvalue6: '',
    }
  },
  name: "nftPcIndex",
}
</script>

<style>
</style>