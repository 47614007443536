<template>
  <div style="display: flex; flex-direction: column; align-items: center;margin-top: 150px;">
    <div style="width: 100%;height: 476px;background: #F9FBFF;display: flex; flex-direction: column; align-items: center;">
      <div style="width: 1245px;height: 476px;display: flex; align-items: center;">
        <div style="width: 298px;height: 465px;margin-left: 139px;display: flex; flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 36px;color: #1A202C;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 55px;">TP侠招募计划</a>
          <div style="display: flex; flex-direction: column;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
          color: #737578;line-height: 34px;text-align: left;font-style: normal;text-transform: none;margin-top: 30px;">
            <a>加入TokenPocket社区</a>
            <a>投身到区块链世界，贡献自己的一份力量</a>
            <a>共同建设Web3.0世界</a>
          </div>
        </div>
        <van-image style="width: 515px;height: 465px;margin-left: 162px;"
                   src="img/pc/recruiting/pcRecruitingDt1.png"></van-image>
      </div>
    </div>
    <div style="width: 1245px;height: 476px;display: flex; align-items: center;margin-top: 83px;">
      <van-image style="width: 315px;height: 476px;margin-left: 125px;"
                 src="img/pc/recruiting/pcRecruitingDt2.png"></van-image>
      <div style="display: flex; flex-direction: column;height: 476px;width: 591px;margin-left: 69px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 38px;color: #1A202C;
          line-height: 45px;text-align: left;font-style: normal;text-transform: none;">关于TP侠</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #7A7A7A;
        line-height: 23px;text-align: left;font-style: normal;text-transform: none;margin-top: 20px;">TP侠是TP社区中的重要一员，我们诚挚地邀请您成为全球TP侠中的一员！</a>
        <div style="width: 591px;height: 51px;border-radius: 11px 11px 11px 11px;border: 1px solid #DDDDDD;margin-top: 40px;display: flex; align-items: center;">
          <div style="width: 32px; height: 32px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
       color: white; font-size: 14px;margin-left: 10px;">
            1
          </div>
          <a style="font-family: Inter, Inter;font-weight: 400;font-size: 14px;color: #555555;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 12px;">热衷于区块链事业并认可它的价值</a>
        </div>
        <div style="width: 591px;height: 51px;border-radius: 11px 11px 11px 11px;border: 1px solid #DDDDDD;margin-top: 19px;display: flex; align-items: center;">
          <div style="width: 32px; height: 32px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
       color: white; font-size: 14px;margin-left: 10px;">
            2
          </div>
          <a style="font-family: Inter, Inter;font-weight: 400;font-size: 14px;color: #555555;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 12px;">作为TokenPocket的忠诚用户，希望为探索区块链增加便利</a>
        </div>
      </div>
    </div>
    <div style="width: 1245px;height: 590px;display: flex; align-items: center;">
      <div style="height: 590px;width: 635px;margin-left: 140px;display: flex; flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 38px;color: #1A202C;
        line-height: 45px;text-align: left;font-style: normal;text-transform: none;margin-top: 74px;">TP侠使命</a>
        <div style="width: 622px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #7A7A7A;
        line-height: 23px;text-align: left;font-style: normal;text-transform: none;margin-top: 19px;">
          <a>在全球范围内，帮助TP钱包服务更多的区块链用户，我们希望您（满足以下其中两项要求即可报名）</a>
        </div>
        <div style="width: 635px;height: 37px;border-radius: 19px 19px 19px 19px;border: 1px solid #DDDDDD;margin-top: 33px;display: flex; align-items: center;">
          <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
            color: white; font-size: 12px;margin-left: 10px;">
            &#x2713;
          </div>
          <a style="font-family: Inter, Inter;font-weight: 400;font-size: 14px;color: #555555;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 10px;">能够通过多种渠道拓展并促进TP与所在国家的的营销公司或热门项目合作</a>
        </div>
        <div style="width: 635px;height: 37px;border-radius: 19px 19px 19px 19px;border: 1px solid #DDDDDD;margin-top: 14px;display: flex; align-items: center;">
          <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
            color: white; font-size: 12px;margin-left: 10px;">
            &#x2713;
          </div>
          <a style="font-family: Inter, Inter;font-weight: 400;font-size: 14px;color: #555555;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 10px;">能够策划符合当地用户需求的市场营销活动</a>
        </div>
        <div style="width: 635px;height: 37px;border-radius: 19px 19px 19px 19px;border: 1px solid #DDDDDD;margin-top: 14px;display: flex; align-items: center;">
          <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
            color: white; font-size: 12px;margin-left: 10px;">
            &#x2713;
          </div>
          <a style="font-family: Inter, Inter;font-weight: 400;font-size: 14px;color: #555555;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 10px;">具有其中一至几项主流社交媒体如Twitter、Youtube、Telegram、Discord等媒体的运营能力</a>
        </div>
        <div style="width: 635px;height: 37px;border-radius: 19px 19px 19px 19px;border: 1px solid #DDDDDD;margin-top: 14px;display: flex; align-items: center;">
          <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
            color: white; font-size: 12px;margin-left: 10px;">
            &#x2713;
          </div>
          <a style="font-family: Inter, Inter;font-weight: 400;font-size: 14px;color: #555555;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 10px;">具有流畅的英语水平，能完成对应的翻译工作</a>
        </div>
        <div style="width: 635px;height: 53px;border-radius: 19px 19px 19px 19px;border: 1px solid #DDDDDD;margin-top: 14px;display: flex; align-items: center;">
          <div>
            <div style="width: 19px; height: 19px; background: #2980FE; border-radius: 50%; display: flex; justify-content: center; align-items: center;
            color: white; font-size: 12px;margin-left: 10px;">
              &#x2713;
            </div>
          </div>
          <a style="font-family: Inter, Inter;font-weight: 400;font-size: 14px;color: #555555;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-left: 10px;">TokenPocket当前计划为区块链市场提供更多的福利，如果您来自印度、美国、
            土耳其、俄罗斯、韩国、越南、菲律宾等，我们将给您提供更多的工作支持</a>
        </div>
      </div>
      <van-image style="width: 328px;height: 560px;margin-left: 24px;"
                 src="img/pc/recruiting/pcRecruitingDt3.png"></van-image>
    </div>
    <div style="width: 100%;height: 516px;background: #07459F;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 36px;color: #FFFFFF;
      line-height: 42px;text-align: left;font-style: normal;text-transform: none;margin-top: 42px;">我们提供的福利</a>
      <div style="display: flex; align-items: center;margin-top: 28px;">
        <div style="width: 226px;height: 314.5px;background-image: url('img/pc/recruiting/pcRecruitingDt4.svg');"></div>
        <div style="width: 226px;height: 314.5px;background-image: url('img/pc/recruiting/pcRecruitingDt5.svg');margin-left: 17px;"></div>
        <div style="width: 226px;height: 314.5px;background-image: url('img/pc/recruiting/pcRecruitingDt6.svg');margin-left: 17px;"></div>
        <div style="width: 226px;height: 314.5px;background-image: url('img/pc/recruiting/pcRecruitingDt7.svg');margin-left: 17px;
        display: flex; flex-direction: column; align-items: center;">
          <van-button type="primary"
                      style="width: 198px;height: 45px;margin-top: 234px;"
                      color="#2980FE;"
                      round
                      url="https://docs.google.com/forms/d/e/1FAIpQLSd3xlzB-ItayKeZJzZDt-qZVhYnc4OuhesWrtFeR6LD3CPjDg/viewform"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 14px;
                color: #FFFFFF;line-height: 16px;text-align: left;font-style: normal;text-transform: none;">加入我们</a></van-button>
        </div>
      </div>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 36px;color: #1A202C;
    line-height: 42px;text-align: left;font-style: normal;text-transform: none;margin-top: 88px;">招募流程</a>
    <div style="display: flex; align-items: center;margin-top: 57px;">
      <van-image src="img/pc/recruiting/pcRecruitingXt1.png"></van-image>
      <van-image src="img/pc/recruiting/pcRecruitingXt2.png"
                 style="margin-left: 67px;margin-right: 67px;"></van-image>
      <van-image src="img/pc/recruiting/pcRecruitingXt3.png"></van-image>
      <van-image src="img/pc/recruiting/pcRecruitingXt4.png"
                 style="margin-left: 67px;margin-right: 67px;"></van-image>
      <van-image src="img/pc/recruiting/pcRecruitingXt5.png"></van-image>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 36px;color: #1A202C;
    line-height: 42px;text-align: left;font-style: normal;text-transform: none;margin-top: 145px;">招募对象</a>
    <div style="display: flex; align-items: center;margin-top: 47px;">
      <div style="width: 305px;height: 247px;border-radius: 25px 25px 25px 25px;border: 2px solid #A4C7FF;display: flex; flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 12px;color: #3988FD;
        line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-top: 16px;margin-left: 22px;">1</a>
        <div style="margin-top: 8px;display: flex; flex-direction: column; align-items: center;">
          <van-image src="img/pc/recruiting/pcRecruitingXt6.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 14px;color: #000000;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-top: 38.5px;">来自全球任何地方</a>
        </div>
      </div>
      <div style="width: 305px;height: 247px;border-radius: 25px 25px 25px 25px;border: 2px solid #A4C7FF;margin-left: 20px;display: flex; flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 12px;color: #3988FD;
        line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-top: 16px;margin-left: 22px;">2</a>
        <div style="margin-top: 8px;display: flex; flex-direction: column; align-items: center;">
          <van-image src="img/pc/recruiting/pcRecruitingXt7.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 14px;color: #000000;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-top: 38.5px;">对区块链保持着无尽的好</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 14px;color: #000000;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">奇和热情</a>
        </div>
      </div>
      <div style="width: 305px;height: 247px;border-radius: 25px 25px 25px 25px;border: 2px solid #A4C7FF;margin-left: 20px;display: flex; flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 12px;color: #3988FD;
        line-height: 12px;text-align: left;font-style: normal;text-transform: none;margin-top: 16px;margin-left: 22px;">3</a>
        <div style="margin-top: 8px;display: flex; flex-direction: column; align-items: center;">
          <van-image src="img/pc/recruiting/pcRecruitingXt8.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 14px;color: #000000;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-top: 38.5px;">填写表格并附上简历，我</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 14px;color: #000000;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">们会尽快联系您</a>
        </div>
      </div>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 36px;color: #1A202C;
    line-height: 42px;text-align: left;font-style: normal;text-transform: none;margin-top: 118px;">关于TokenPocket</a>
    <div style="margin-top: 24px;display: flex; align-items: center;margin-bottom: 55px;">
      <div style="display: flex;flex-direction: column;">
        <van-image src="img/pc/recruiting/pcRecruitingDt8.png"></van-image>
        <van-image src="img/pc/recruiting/pcRecruitingDt9.svg"></van-image>
      </div>
      <div style="width: 365px;height: 509px;border-radius: 31px 31px 31px 31px;border: 1px solid #E3E3E3;margin-left: 15px;
      display: flex; align-items: center;flex-direction: column;">
        <a style="width: 290px;font-family: Inter, Inter;font-weight: bold;font-size: 18px;color: #000000;
        line-height: 21px;text-align: left;font-style: normal;text-transform: none;margin-top: 35px;">TokenPocket 全球领先的多链自托管钱包</a>
        <van-image style="margin-top: 43px;"
                   src="img/pc/recruiting/pcRecruitingDt10.png"></van-image>
        <van-button type="primary"
                    style="width: 257px;height: 48px;margin-top: 27px;"
                    color="#2980FE;"
                    round
                    url="https://docs.google.com/forms/d/e/1FAIpQLSd3xlzB-ItayKeZJzZDt-qZVhYnc4OuhesWrtFeR6LD3CPjDg/viewform">
          <a style="font-family: Inter, Inter;font-weight: 500;font-size: 14px;
                color: #FFFFFF;line-height: 16px;text-align: left;font-style: normal;text-transform: none;">加入我们</a></van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "recruitingPcIndex",
}
</script>

<style>
</style>